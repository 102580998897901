import React from "react";
import { Link } from "react-router-dom";
import { Dropdown, Tab, Nav } from "react-bootstrap";
import CountUp from "react-countup";

// Images
import ic_stat1 from "../../../images/svg/ic_stat1.svg";
import ic_stat2 from "../../../images/svg/ic_stat2.svg";
import OrderSummaryChart from "../davur/home/OrdersSummaryChart";
import RevenueChart from "../davur/home/RevenueChart";
import ActiveChart1 from "../davur/home/ActivityChart1";
import ActiveChart2 from "../davur/home/ActivityChart2";
import ActiveChart3 from "../davur/home/ActivityChart3";
// import avatar3 from "../../../images/avatar/3.jpg";
// import avatar4 from "../../../images/avatar/4.jpg";
// import svg from "../../../images/svg/welcom-card.svg";

// import { Dropdown, Nav, Tab } from "react-bootstrap";
// import LineChart7 from "../charts/Chartjs/line7";
// import BarChart7 from "../charts/Chartjs/bar7";
// const ApexPie3 = loadable(() =>
//    pMinDelay(import("../charts/apexcharts/Pie3"), 1000)
// );

const Home = () => {
  return (
    <>
      <div className="form-head d-flex mb-3 align-items-start">
        <div className="mr-auto d-none d-lg-block">
          <h2 className="text-black font-w600 mb-0">Dashboard</h2>
          <p className="mb-0">Welcome!</p>
        </div>
        {/* <Dropdown className="dropdown custom-dropdown">
          <Dropdown.Toggle
            className="btn btn-sm btn-primary light d-flex align-items-center svg-btn"
            data-toggle="dropdown"
          >
            <svg
              width={28}
              height={28}
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path
                  d="M22.4281 2.856H21.8681V1.428C21.8681 0.56 21.2801 0 20.4401 0C19.6001 0 19.0121 0.56 19.0121 1.428V2.856H9.71606V1.428C9.71606 0.56 9.15606 0 8.28806 0C7.42006 0 6.86006 0.56 6.86006 1.428V2.856H5.57206C2.85606 2.856 0.560059 5.152 0.560059 7.868V23.016C0.560059 25.732 2.85606 28.028 5.57206 28.028H22.4281C25.1441 28.028 27.4401 25.732 27.4401 23.016V7.868C27.4401 5.152 25.1441 2.856 22.4281 2.856ZM5.57206 5.712H22.4281C23.5761 5.712 24.5841 6.72 24.5841 7.868V9.856H3.41606V7.868C3.41606 6.72 4.42406 5.712 5.57206 5.712ZM22.4281 25.144H5.57206C4.42406 25.144 3.41606 24.136 3.41606 22.988V12.712H24.5561V22.988C24.5841 24.136 23.5761 25.144 22.4281 25.144Z"
                  fill="#2F4CDD"
                />
              </g>
            </svg>
            <div className="text-left ml-3">
              <span className="d-block fs-16">Filter Periode</span>
              <small className="d-block fs-13">4 June 2020 - 4 July 2020</small>
            </div>
            <i className="fa fa-angle-down scale5 ml-3" />
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
            <Dropdown.Item className="dropdown-item" to="#">
              4 June 2020 - 4 July 2020
            </Dropdown.Item>
            <Dropdown.Item className="dropdown-item" to="#">
              5 july 2020 - 4 Aug 2020
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}
      </div>
    </>
  );
};

export default Home;
