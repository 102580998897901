import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className="copyright">
        <p>
          © 2021 Crafted by{" "}
          <a href="https://agifly.be/" target="_blank">
            Agifly
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
