import React, { useCallback, useEffect, useState } from "react";
import { Card, Tab, Nav, Modal, Button } from "react-bootstrap";
import CustomTable from "../../../components/customTable";
import AddContentModal from "../../../components/modals/ContentModal";
import ParentModal from "../../../components/modals/ParentModal";
import DetailModal from "../../../components/modals/DetailModal";
import Spinner from "react-bootstrap/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Api Services
import { getPage, deletePage } from "../../../services/pages";
import { deletePageContent } from "../../../services/page_content";

// Table Head
const tableHeader = ["#", "Question", "Image", "Action"];
const tableDetailsHeader = ["#", "Key", "Value", "Action"];

const Faq = () => {
  const [currentTab, setCurrentTab] = useState(false);
  const [state, setState] = useState({
    activeTab: "",
    basicModal: false,
    detailModal: false,
    addContentModal: false,
    pageId: "",
    data: [],
  });

  const getCall = (tab) => {
    getPage("faq").then((response) => {
      const data = response.data;
      if (tab) {
        setCurrentTab(tab);
        setState((st) => ({
          ...st,
          activeTab: tab,
          data: data,
        }));
      } else {
        setCurrentTab(data[0].title);
        setState((st) => ({
          ...st,
          activeTab: data[0].title,
          data: data,
        }));
      }
    });
  };
  // Delete
  const deleteCall = (type, id) => {
    if (type == "page") {
      deletePage(id)
        .then((response) => {
          if (response.status === 200) {
            getCall();
            notify("success", "Record Deleted");
          }
        })
        .catch((error) => {
          error.response.statusText == ""
            ? notify(error.response.data.message)
            : notify(error.response.statusText);
        });
    }
    if (type == "pageContent") {
      deletePageContent(id)
        .then((response) => {
          if (response.status === 200) {
            getCall(state.activeTab);
            notify("success", "Record Deleted");
          }
        })
        .catch((error) => {
          error.response.statusText == ""
            ? notify(error.response.data.message)
            : notify(error.response.statusText);
        });
    }
  };
  useEffect(() => {
    getCall();
  }, []);
  const setRecord = useCallback((record) => {
    setState((st) => ({ ...st, record, basicModal: true }));
  }, []);
  const setRecordDetail = useCallback((record) => {
    setState((st) => ({ ...st, record, detailModal: true }));
  }, []);
  const filterData = state.data.filter((obj) => obj.title === state.activeTab);
  // Notification
  const notify = (status, text) => {
    if (status == "success") {
      toast.success(`✅ ${text}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else if (status == "error") {
      toast.error(`❌ ${text} !`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
    }
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Add New Content */}
      <AddContentModal
        StateData={state}
        SetState={setState}
        GetData={getCall}
        setNotification={notify}
      />
      {/* Parent Modal */}
      <ParentModal
        StateData={state}
        SetState={setState}
        GetData={getCall}
        Tab="support"
        setNotification={notify}
      />
      {/* Details Modal */}
      <DetailModal
        StateData={state}
        SetState={setState}
        GetData={getCall}
        Tab="support"
        setNotification={notify}
      />
      {state.activeTab === currentTab ? (
        <>
          <div className="form-row">
            <div className="form-group col-md-12">
              <Button
                className="mr-2 btn-rounded"
                variant="primary"
                onClick={() =>
                  setState((st) => ({
                    ...st,
                    pageId: "",
                    addContentModal: true,
                  }))
                }
              >
                Add New Faq
              </Button>
            </div>
          </div>
          <Card>
            <Card.Header>
              <Card.Title>{state.activeTab}</Card.Title>
            </Card.Header>
            <Card.Body>
              <div className="custom-tab-1">
                <Tab.Container defaultActiveKey={state.activeTab}>
                  <Nav as="ul" className="nav-tabs">
                    {state?.data?.map((tab, i) => (
                      <Nav.Item as="li" key={i}>
                        <Nav.Link
                          onClick={() => {
                            setCurrentTab(tab.title);
                            setState((st) => ({
                              ...st,
                              activeTab: tab.title,
                            }));
                          }}
                          eventKey={tab.title}
                        >
                          {i + 1}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                  <Tab.Content activeTab={state.activeTab} className="pt-4">
                    {filterData?.map((page) => (
                      <Tab.Pane
                        eventKey={page.title}
                        key={page.id}
                        tabId={page.id}
                      >
                        <CustomTable
                          onDelete={deleteCall}
                          data={filterData}
                          headers={tableHeader}
                          onEdit={setRecord}
                        />
                        <Card>
                          <Card.Header>
                            <Card.Title>Details</Card.Title>
                            <Button
                              className="mr-2"
                              variant="primary btn-rounded float-right btn-sm"
                              onClick={() =>
                                setState((st) => ({
                                  ...st,
                                  pageId: page.id,
                                  addContentModal: true,
                                }))
                              }
                            >
                              <span className="btn-icon-left text-primary">
                                <i className="fa fa-plus color-primary" />
                              </span>
                              Add
                            </Button>
                          </Card.Header>
                          <Card.Body>
                            <CustomTable
                              headers={tableDetailsHeader}
                              onDelete={deleteCall}
                              onEdit={setRecordDetail}
                              detailsTable={filterData[0]["pageContent"]}
                            />
                          </Card.Body>
                        </Card>
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Card.Body>
          </Card>
        </>
      ) : (
        <div className="row">
          <Spinner animation="border" className="m-auto" />
        </div>
      )}
    </>
  );
};
export default Faq;
