import React, { useEffect, useState, Fragment } from "react";
import { Row, Card, Col, ListGroup, Badge } from "react-bootstrap";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import "react-toastify/dist/ReactToastify.css";

const UserActivity = () => {
  // Define All Cards Data
  const strokes = ["All", "two strokes", "four strokes"];
  const engines = [
    "All",
    "Automatic Viscosimeter",
    "Tanks",
    "Engine",
    "Economiser",
    "Scrubber",
    "Funnel",
  ];
  const fuel = ["All", "VSLFO", "MGO", "HFO", "Biofuel"];
  const issuesArr = [
    {
      issuestype: "Cat fines",
    },
    {
      issuestype: "High TSP",
    },
    {
      issuestype: "Water",
    },
    {
      issuestype: "Sludge_VSLFO",
    },
    {
      issuestype: "Sludge_MGO",
    },
    {
      issuestype: "Sludge_HFO",
    },
    {
      issuestype: "Chem.contamin",
    },
    {
      issuestype: "CCAI",
    },
    {
      issuestype: "Wax",
    },
    {
      issuestype: "Bacteria",
    },
    {
      issuestype: "Low lubricity",
    },
    {
      issuestype: "Oxydation",
    },
    {
      issuestype: "UnstablePh",
    },
  ];

  //const issueTab = ["Result 1", "Result 2", "Result 3", "Result 4"];
  const [state, setState] = useState({
    stroke: [],
    engine: [],
    fuel: [],
    issue: [],
    issueDetail: [],
    selectedStroke: "",
    selectedEngine: "",
    selectedFuel: "",
    selectedIssue: "",
  });

  // Get User Activity Data
  const getCall = () => {
    axios
      .get(process.env.REACT_APP_BACKEND_URL + `userdata`)
      .then((response) => {
        const data = response.data.data;
        setState((st) => ({
          ...st,
          stroke: data,
        }));
      });
  };

  useEffect(() => {
    getCall();
  }, []);

  // Counter For Stroke Type
  const countStrokes = (type) => {
    var countTypes = "";

    if (type == "All") {
      countTypes = state?.stroke.userdata;
    } else {
      countTypes = state?.stroke.userdata.filter(
        (data) => data.engineType === type
      );
    }

    return countTypes.length;
  };

  // Counter For Engine Type
  const countEngines = (type) => {
    var countTypes = "";

    if (type == "All") {
      if (state.selectedStroke == "All") {
        countTypes = state?.stroke.userdata;
      } else {
        countTypes = state?.stroke.userdata.filter(
          (data) => data.engineType === state.selectedStroke
        );
      }
    } else {
      if (state.selectedStroke == "All") {
        countTypes = state?.stroke.userdata.filter(
          (data) => data.enginePart === type
        );
      } else {
        countTypes = state?.stroke.userdata.filter(
          (data) =>
            data.enginePart === type && data.engineType == state.selectedStroke
        );
      }
    }

    return countTypes.length;
  };

  // Counter For Fuel Type
  const countFuel = (type) => {
    var countTypes = "";

    if (type == "All") {
      if (state.selectedEngine == "All" && state.selectedStroke == "All") {
        countTypes = state?.stroke.userdata;
      } else if (
        state.selectedEngine == "All" &&
        state.selectedStroke !== "All"
      ) {
        countTypes = state?.stroke.userdata.filter(
          (data) => data.engineType === state.selectedStroke
        );
      } else if (
        state.selectedEngine !== "All" &&
        state.selectedStroke == "All"
      ) {
        countTypes = state?.stroke.userdata.filter(
          (data) => data.enginePart === state.selectedEngine
        );
      } else {
        countTypes = state?.stroke.userdata.filter(
          (data) =>
            data.enginePart === state.selectedEngine &&
            data.engineType == state.selectedStroke
        );
      }
    } else {
      if (state.selectedEngine == "All" && state.selectedStroke == "All") {
        countTypes = state?.stroke.userdata.filter(
          (data) => data.fuelType === type
        );
      } else if (
        state.selectedEngine == "All" &&
        state.selectedStroke !== "All"
      ) {
        countTypes = state?.stroke.userdata.filter(
          (data) =>
            data.fuelType === type && data.engineType == state.selectedStroke
        );
      } else if (
        state.selectedEngine !== "All" &&
        state.selectedStroke == "All"
      ) {
        countTypes = state?.stroke.userdata.filter(
          (data) =>
            data.fuelType === type && data.enginePart === state.selectedEngine
        );
      } else {
        countTypes = state?.stroke.userdata.filter(
          (data) =>
            data.fuelType === type &&
            data.enginePart === state.selectedEngine &&
            data.engineType == state.selectedStroke
        );
      }
    }

    return countTypes.length;
  };

  // Counter For Issue Type
  const countIssue = (type) => {
    var countTypes = "";

    if (
      state.selectedStroke == "All" &&
      state.selectedEngine == "All" &&
      state.selectedFuel == "All"
    ) {
      if (type == "Sludge_VSLFO") {
        countTypes = state?.stroke.userdata.filter(
          (data) => data.issueType == "Sludge" && data.fuelType == "VSLFO"
        );
      } else if (type == "Sludge_HFO") {
        countTypes = state?.stroke.userdata.filter(
          (data) => data.issueType == "Sludge" && data.fuelType == "HFO"
        );
      } else {
        countTypes = state?.stroke.userdata.filter(
          (data) => data.issueType === type
        );
      }
    } else if (
      state.selectedStroke == "All" &&
      state.selectedEngine == "All" &&
      state.selectedFuel != "All"
    ) {
      countTypes = state?.stroke.userdata.filter(
        (data) =>
          data.issueType === type && data.fuelType === state.selectedFuel
      );
    } else if (
      state.selectedStroke == "All" &&
      state.selectedEngine != "All" &&
      state.selectedFuel != "All"
    ) {
      countTypes = state?.stroke.userdata.filter(
        (data) =>
          data.issueType === type &&
          data.enginePart === state.selectedEngine &&
          data.fuelType === state.selectedFuel
      );
    } else if (
      state.selectedStroke !== "All" &&
      state.selectedEngine == "All" &&
      state.selectedFuel == "All"
    ) {
      if (type == "Sludge_VSLFO") {
        countTypes = state?.stroke.userdata.filter(
          (data) =>
            data.issueType == "Sludge" &&
            data.fuelType == "VSLFO" &&
            data.engineType == state.selectedStroke
        );
      } else if (type == "Sludge_HFO") {
        countTypes = state?.stroke.userdata.filter(
          (data) =>
            data.issueType == "Sludge" &&
            data.fuelType == "HFO" &&
            data.engineType == state.selectedStroke
        );
      } else {
        countTypes = state?.stroke.userdata.filter(
          (data) =>
            data.issueType === type && data.engineType == state.selectedStroke
        );
      }
    } else if (
      state.selectedStroke !== "All" &&
      state.selectedEngine !== "All" &&
      state.selectedFuel == "All"
    ) {
      if (type == "Sludge_VSLFO") {
        countTypes = state?.stroke.userdata.filter(
          (data) =>
            data.issueType == "Sludge" &&
            data.fuelType == "VSLFO" &&
            data.engineType == state.selectedStroke &&
            data.enginePart === state.selectedEngine
        );
      } else if (type == "Sludge_HFO") {
        countTypes = state?.stroke.userdata.filter(
          (data) =>
            data.issueType == "Sludge" &&
            data.fuelType == "HFO" &&
            data.engineType == state.selectedStroke &&
            data.enginePart === state.selectedEngine
        );
      } else {
        countTypes = state?.stroke.userdata.filter(
          (data) =>
            data.issueType === type &&
            data.engineType == state.selectedStroke &&
            data.enginePart === state.selectedEngine
        );
      }
    } else if (
      state.selectedStroke !== "All" &&
      state.selectedEngine == "All" &&
      state.selectedFuel !== "All"
    ) {
      countTypes = state?.stroke.userdata.filter(
        (data) =>
          data.issueType === type &&
          data.engineType == state.selectedStroke &&
          data.fuelType === state.selectedFuel
      );
    } else if (
      state.selectedStroke == "All" &&
      state.selectedEngine !== "All" &&
      state.selectedFuel == "All"
    ) {
      if (type == "Sludge_VSLFO") {
        countTypes = state?.stroke.userdata.filter(
          (data) =>
            data.issueType == "Sludge" &&
            data.fuelType == "VSLFO" &&
            data.enginePart === state.selectedEngine
        );
      } else if (type == "Sludge_HFO") {
        countTypes = state?.stroke.userdata.filter(
          (data) =>
            data.issueType == "Sludge" &&
            data.fuelType == "HFO" &&
            data.enginePart === state.selectedEngine
        );
      } else {
        countTypes = state?.stroke.userdata.filter(
          (data) =>
            data.issueType === type && data.enginePart === state.selectedEngine
        );
      }
    } else {
      countTypes = state?.stroke.userdata.filter(
        (data) =>
          data.issueType === type &&
          data.engineType == state.selectedStroke &&
          data.enginePart === state.selectedEngine &&
          data.fuelType === state.selectedFuel
      );
    }

    return countTypes.length;
  };

  // Counter For Issue Details
  const countIssueTab = (type) => {
    var countTypes = "";
    var selected = "";
    var stroke = "";
    var engine = "";
    var fuel = "";
    if (state.selectedIssue == "Sludge_VSLFO") {
      selected = "Sludge";
    } else if (state.selectedIssue == "Sludge_HFO") {
      selected = "Sludge";
    } else {
      selected = state.selectedIssue;
    }

    if (state.selectedStroke !== "All") {
      stroke = state.selectedStroke;
    }

    if (state.selectedEngine !== "All") {
      engine = state.selectedEngine;
    }

    if (state.selectedFuel !== "All") {
      fuel = state.selectedFuel;
    }

    if (stroke !== "" && engine !== "" && fuel !== "") {
      countTypes = state?.stroke.userdata.filter(
        (data, i) =>
          data.engineType == stroke &&
          data.enginePart == engine &&
          data.fuelType == fuel &&
          data.issueType == selected &&
          data.issueTab == type
      );
    } else if (stroke == "" && engine !== "" && fuel !== "") {
      countTypes = state?.stroke.userdata.filter(
        (data, i) =>
          data.enginePart == engine &&
          data.fuelType == fuel &&
          data.issueType == selected &&
          data.issueTab == type
      );
    } else if (stroke == "" && engine == "" && fuel !== "") {
      countTypes = state?.stroke.userdata.filter(
        (data, i) =>
          data.fuelType == fuel &&
          data.issueType == selected &&
          data.issueTab == type
      );
    } else if (stroke !== "" && engine == "" && fuel == "") {
      countTypes = state?.stroke.userdata.filter(
        (data, i) =>
          data.engineType == stroke &&
          data.issueType == selected &&
          data.issueTab == type
      );
    } else if (stroke !== "" && engine !== "" && fuel == "") {
      countTypes = state?.stroke.userdata.filter(
        (data, i) =>
          data.engineType == stroke &&
          data.enginePart == engine &&
          data.issueType == selected &&
          data.issueTab == type
      );
    } else if (stroke == "" && engine !== "" && fuel == "") {
      countTypes = state?.stroke.userdata.filter(
        (data, i) =>
          data.enginePart == engine &&
          data.issueType == selected &&
          data.issueTab == type
      );
    } else {
      countTypes = state?.stroke.userdata.filter(
        (data, i) => data.issueType == selected && data.issueTab == type
      );
    }

    return countTypes.length;
  };

  // Filter Card Based on Selection
  const filterFn = (type, name) => {
    if (type == "stroke") {
      setState((st) => ({
        ...st,
        engine: state?.stroke.userdata,
        selectedStroke: name,
      }));
    }

    if (type == "engine") {
      setState((st) => ({
        ...st,
        fuel: state?.stroke.userdata,
        selectedEngine: name,
      }));
    }
    if (type == "fuel") {
      if (name == "All") {
        setState((st) => ({
          ...st,
          issue: issuesArr,
          selectedFuel: name,
        }));
      } else {
        state?.stroke.fuel.map(
          (data, i) =>
            data.fueltype == name &&
            setState((st) => ({
              ...st,
              issue: data.issuetypes,
              selectedFuel: name,
            }))
        );
      }
    }

    if (type == "issue") {
      if (name == "Sludge" || name == "Sludge_VSLFO" || name == "Sludge_HFO") {
        if (name == "Sludge_VSLFO") {
          state?.stroke.issueTab.map(
            (data, i) =>
              data.fueltype == "VSLFO" &&
              setState((st) => ({
                ...st,
                issueDetail: data.issuetypes[0].issueContent,
                selectedIssue: name,
              }))
          );
        } else if (name == "Sludge_HFO") {
          state?.stroke.issueTab.map(
            (data, i) =>
              data.fueltype == "HFO" &&
              setState((st) => ({
                ...st,
                issueDetail: data.issuetypes[0].issueContent,
                selectedIssue: name,
              }))
          );
        } else {
          state?.stroke.issueTab.map(
            (data, i) =>
              data.fueltype == state.selectedFuel &&
              setState((st) => ({
                ...st,
                issueDetail: data.issuetypes[0].issueContent,
                selectedIssue: name,
              }))
          );
        }
      } else {
        setState((st) => ({
          ...st,
          issueDetail: [],
          selectedIssue: "",
        }));
      }
    }
    // Reset Other Card if Stroke Type Change
    if (type == "stroke" && state.engine.length > 0) {
      setState((st) => ({
        ...st,
        fuel: [],
        issue: [],
        issueDetail: [],
        selectedEngine: "",
        selectedFuel: "",
        selectedIssue: "",
      }));
    }
    // Reset Other Card if Engine Type Change
    if (type == "engine" && state.fuel.length > 0) {
      setState((st) => ({
        ...st,
        issue: [],
        issueDetail: [],
        selectedFuel: "",
        selectedIssue: "",
      }));
    }

    // Reset Other Card if Engine Type Change
    if (type == "fuel" && state.issue.length > 0) {
      setState((st) => ({
        ...st,
        issueDetail: [],
        selectedIssue: "",
      }));
    }
  };
  return (
    <>
      {state?.stroke.total > 0 ? (
        <div
          className="row"
          style={{ display: "flex", flexWrap: "nowrap", overflowX: "auto" }}
        >
          <Col xl="3" style={{ flex: "0 0 auto" }}>
            <Card className="h-auto">
              <Card.Header>
                <Card.Title>Strokes</Card.Title>
              </Card.Header>
              <Card.Body className="p-0">
                <div className="basic-list-group">
                  <ListGroup>
                    {strokes.map((list, i) => (
                      <Fragment key={i}>
                        <ListGroup.Item
                          className="d-flex justify-content-between align-items-center"
                          action
                          active={state.selectedStroke == list ? true : false}
                          onClick={() => filterFn("stroke", list)}
                        >
                          {list.toUpperCase()}
                          <Badge variant="success" pill>
                            {countStrokes(list)}
                          </Badge>
                        </ListGroup.Item>
                      </Fragment>
                    ))}
                  </ListGroup>
                </div>
              </Card.Body>
            </Card>
          </Col>
          {state?.engine.length > 0 && (
            <Col xl="3" style={{ flex: "0 0 auto" }}>
              <Card className="h-auto">
                <Card.Header>
                  <Card.Title>Engine</Card.Title>
                </Card.Header>
                <Card.Body className="p-0">
                  <div className="basic-list-group">
                    <ListGroup>
                      {engines.map((list, i) => (
                        <Fragment key={i}>
                          <ListGroup.Item
                            className="d-flex justify-content-between align-items-center"
                            action
                            active={state.selectedEngine == list ? true : false}
                            onClick={() => filterFn("engine", list)}
                          >
                            {list}
                            <Badge variant="success" pill>
                              {countEngines(list)}
                            </Badge>
                          </ListGroup.Item>
                        </Fragment>
                      ))}
                    </ListGroup>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          )}
          {state?.fuel.length > 0 && (
            <Col xl="3" style={{ flex: "0 0 auto" }}>
              <Card className="h-auto">
                <Card.Header>
                  <Card.Title>Fuel</Card.Title>
                </Card.Header>
                <Card.Body className="p-0">
                  <div className="basic-list-group">
                    <ListGroup>
                      {fuel.map((list, i) => (
                        <Fragment key={i}>
                          <ListGroup.Item
                            className="d-flex justify-content-between align-items-center"
                            action
                            active={state.selectedFuel == list ? true : false}
                            onClick={() => filterFn("fuel", list)}
                          >
                            {list}
                            <Badge variant="success" pill>
                              {countFuel(list)}
                            </Badge>
                          </ListGroup.Item>
                        </Fragment>
                      ))}
                    </ListGroup>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          )}
          {state?.issue.length > 0 && (
            <Col xl="3" style={{ flex: "0 0 auto" }}>
              <Card className="h-auto">
                <Card.Header>
                  <Card.Title>Issue</Card.Title>
                </Card.Header>
                <Card.Body className="p-0">
                  <div className="basic-list-group">
                    <ListGroup>
                      {state?.issue.map((issuelist, i) => (
                        <Fragment key={i}>
                          <ListGroup.Item
                            className="d-flex justify-content-between align-items-center"
                            action
                            active={
                              state.selectedIssue == issuelist.issuestype
                                ? true
                                : false
                            }
                            onClick={() => {
                              filterFn("issue", issuelist.issuestype);
                            }}
                          >
                            {issuelist.issuestype}
                            <Badge variant="success" pill>
                              {countIssue(issuelist.issuestype)}
                            </Badge>
                          </ListGroup.Item>
                        </Fragment>
                      ))}
                    </ListGroup>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          )}
          {state?.issueDetail.length > 0 && (
            <Col xl="4" style={{ flex: "0 0 auto" }}>
              <Card className="h-auto">
                <Card.Header>
                  <Card.Title>Result</Card.Title>
                </Card.Header>
                <Card.Body className="p-0">
                  <div className="basic-list-group">
                    <ListGroup>
                      {state?.issueDetail.map((issueTab, i) => (
                        <Fragment key={i}>
                          <ListGroup.Item
                            className="d-flex justify-content-between align-items-center"
                            action
                          >
                            {issueTab.value}
                            <Badge variant="success" pill>
                              {countIssueTab(issueTab.value)}
                            </Badge>
                          </ListGroup.Item>
                        </Fragment>
                      ))}
                    </ListGroup>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          )}
        </div>
      ) : (
        <div className="row">
          <Spinner animation="border" className="m-auto" />
        </div>
      )}
    </>
  );
};
export default UserActivity;
