import React, { useCallback, useEffect, useState } from "react";
import { Card, Tab, Nav, Modal, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Table from "../../components/customTable/table";
import Spinner from "react-bootstrap/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Api Services
import {
  getGreenometer,
  getGreenometerFormula,
  createGreenometer,
  updateGreenometer,
  deleteGreenometer,
} from "../../services/greenometer";

// Table Head
const tableHeader = ["#", "Key", "Value", "Action"];
const formulaTableHeader = ["#", "Year", "Tree", "Action"];

const GreenoMeter = () => {
  const [currentTab, setCurrentTab] = useState(false);
  const [state, setState] = useState({
    basicModal: false,
    activeTab: "Greenometer Content",
    tabData: [],
    data: [],
  });

  const tabData = [
    {
      name: "Greenometer Content",
    },
    {
      name: "Greenometer Formula",
    },
  ];
  // Get Greenometer Content
  const getCall = () => {
    getGreenometer().then((response) => {
      const data = response.data.data.response;
      setState((st) => ({
        ...st,
        data: data,
      }));
    });
  };
  // Get Formula
  const getCallFormula = () => {
    getGreenometerFormula().then((response) => {
      const data1 = response.data.data;
      setState((st) => ({
        ...st,
        data: data1,
      }));
    });
  };
  // Delete
  const deleteCall = (id) => {
    deleteGreenometer(id)
      .then((response) => {
        if (response.data.success === true) {
          getCallFormula();
          notify("success", "Recored Deleted");
        }
      })
      .catch((error) => {
        error.response.statusText == ""
          ? notify(error.response.data.data.message)
          : notify(error.response.statusText);
      });
  };
  useEffect(() => {
    getCall();
  }, []);
  // Call formula api
  useEffect(() => {
    if (state.activeTab == "Greenometer Formula") {
      getCallFormula();
    } else {
      getCall();
    }
  }, [currentTab]);
  const setRecord = useCallback((record) => {
    setState((st) => ({ ...st, record, basicModal: true }));
  }, []);
  // Notification
  const notify = (status, text) => {
    if (status == "success") {
      toast.success(`✅ ${text}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else if (status == "error") {
      toast.error(`❌ ${text} !`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
    }
  };
  var initialValues;
  var validationSchema;
  if (state?.record?.key) {
    initialValues = {
      value: state?.record?.value || "",
      file: "",
      url: state?.record?.url || "",
    };
    // Schema for yup
    validationSchema = Yup.object().shape({
      value: Yup.string().required("* Value is required"),
    });
  } else {
    initialValues = {
      year: state?.record?.year || "",
      totalTrees: state?.record?.totalTrees || "",
    };
    validationSchema = Yup.object().shape({
      year: Yup.string().required("* year is required"),
      totalTrees: Yup.string().required("* totalTrees is required"),
    });
  }
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Modal className="fade" show={state.basicModal}>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            let formData = new FormData();
            if (values.value || values.file || values.url) {
              formData.append("value", values.value);
              formData.append("file", values.file);
              formData.append("url", values.url);
              let api = "greenometer/updatedetails/";
              let id = state?.record?.id;
              axios
                .put(process.env.REACT_APP_BACKEND_URL + api + id, formData)
                .then((response) => {
                  if (response.data.success === true) {
                    setState((st) => ({ ...st, basicModal: false }));
                    getCall();
                    notify("success", "Record Updated");
                  }
                })
                .catch((error) => {
                  error.response.statusText == ""
                    ? notify("error", error.response.data.data.message)
                    : notify("error", error.response.statusText);
                });
            } else {
              if (state?.record?.id) {
                updateGreenometer(state?.record?.id, values)
                  .then((response) => {
                    if (response.data.success === true) {
                      setState((st) => ({ ...st, basicModal: false }));
                      getCallFormula();
                      notify("success", "Record Updated");
                    }
                  })
                  .catch((error) => {
                    error.response.statusText == ""
                      ? notify("error", error.response.data.data.message)
                      : notify("error", error.response.statusText);
                  });
              } else {
                createGreenometer(values).then((response) => {
                  if (response.data.success === true) {
                    setState((st) => ({ ...st, basicModal: false }));
                    getCallFormula();
                    notify("success", "Record Added");
                  }
                });
              }
            }
          }}
          // Hooks up our validationSchema to Formik
          validationSchema={validationSchema}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            } = props;
            return (
              <>
                <form action="" onSubmit={handleSubmit}>
                  <Modal.Header>
                    <Modal.Title>Edit Content</Modal.Title>
                    <Button
                      variant=""
                      className="close"
                      onClick={() =>
                        setState((st) => ({ ...st, basicModal: false }))
                      }
                    >
                      <span>&times;</span>
                    </Button>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        {state?.record?.key ? (
                          <>
                            <label>{state?.record?.key}</label>
                            {state?.record?.key == "paragraph_description" ? (
                              <textarea
                                className="form-control"
                                value={values.value}
                                name="value"
                                onChange={handleChange}
                                rows="8"
                              />
                            ) : state?.record?.key == "image" ? (
                              state?.record?.url !== "" &&
                              state?.record?.url !== null ? (
                                <>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="url"
                                    placeholder="Type here"
                                    value={values.value}
                                    onChange={handleChange}
                                  />
                                  <div className="custom-file mt-2">
                                    <input
                                      name="file"
                                      type="file"
                                      className="custom-file-input"
                                      // values={values.value}
                                      onChange={(event) => {
                                        setFieldValue(
                                          "file",
                                          event.currentTarget.files[0]
                                        );
                                      }}
                                    />
                                    <label className="custom-file-label">
                                      {values.file
                                        ? values.file.name
                                        : "Choose file"}
                                    </label>
                                  </div>
                                </>
                              ) : (
                                <div className="custom-file">
                                  <input
                                    name="file"
                                    type="file"
                                    className="custom-file-input"
                                    // values={values.value}
                                    onChange={(event) => {
                                      setFieldValue(
                                        "file",
                                        event.currentTarget.files[0]
                                      );
                                    }}
                                  />
                                  <label className="custom-file-label">
                                    {values.file
                                      ? values.file.name
                                      : "Choose file"}
                                  </label>
                                </div>
                              )
                            ) : (
                              <input
                                type="text"
                                className="form-control"
                                name="value"
                                placeholder="Type here"
                                value={values.value}
                                onChange={handleChange}
                              />
                            )}
                          </>
                        ) : (
                          <>
                            <label>Year</label>
                            <input
                              type="text"
                              className="form-control"
                              name="year"
                              placeholder="Type here"
                              value={values.year}
                              onChange={handleChange}
                              disabled={state?.record?.year ? true : false}
                            />
                            <label>Trees</label>
                            <input
                              type="text"
                              className="form-control"
                              name="totalTrees"
                              placeholder="Type here"
                              value={values.totalTrees}
                              onChange={handleChange}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      onClick={() =>
                        setState((st) => ({ ...st, basicModal: false }))
                      }
                      variant="danger light btn-sm"
                    >
                      Close
                    </Button>
                    <input
                      type="submit"
                      value="Save changes"
                      className="btn btn-primary btn-sm"
                    />
                  </Modal.Footer>
                </form>
              </>
            );
          }}
        </Formik>
      </Modal>
      {state?.data.length > 0 ? (
        <>
          <div className="form-row">
            <div className="form-group col-md-12">
              <Button
                className="mr-2 btn-rounded"
                variant="primary"
                onClick={() =>
                  setState((st) => ({
                    ...st,
                    record: "",
                    basicModal: true,
                    tabData: tabData,
                  }))
                }
              >
                Add New
              </Button>
            </div>
          </div>
          <Card>
            <Card.Header>
              <Card.Title>GreenoMeter</Card.Title>
            </Card.Header>
            <Card.Body>
              <div className="custom-tab-1">
                <Tab.Container defaultActiveKey={state.activeTab}>
                  <Nav as="ul" className="nav-tabs">
                    {tabData.map((data, i) => (
                      <Nav.Item as="li" key={i}>
                        <Nav.Link
                          onClick={() => {
                            setCurrentTab(data.name);
                            setState((st) => ({
                              ...st,
                              activeTab: data.name,
                            }));
                          }}
                          eventKey={data.name}
                        >
                          {/* <i className={`la la-${data.icon} mr-2`} /> */}
                          {data.name}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                  <Tab.Content activeTab={state.activeTab} className="pt-4">
                    <Tab.Pane eventKey={state.activeTab}>
                      {state.activeTab == "Greenometer Formula" ? (
                        <Table
                          data={state?.data}
                          headers={formulaTableHeader}
                          onEdit={setRecord}
                          onDelete={deleteCall}
                          pageName="greenometerFormula"
                        />
                      ) : (
                        <Table
                          data={state?.data}
                          headers={tableHeader}
                          onEdit={setRecord}
                          pageName="greenometer"
                        />
                      )}
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Card.Body>
          </Card>
        </>
      ) : (
        <div className="row">
          <Spinner animation="border" className="m-auto" />
        </div>
      )}
    </>
  );
};
export default GreenoMeter;
