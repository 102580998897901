import React, { useCallback, useEffect, useState, useRef } from "react";
import { Card, Tab, Nav, Modal, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import swal from "sweetalert";
// Api Services
import { updatePage } from "../../services/pages";

const ParentModal = ({
  StateData,
  SetState,
  GetData,
  Tab,
  setNotification,
}) => {
  // Schema for yup
  var validationSchema = Yup.object().shape({
    title: Yup.string().required("* Title is required"),
    decription: Yup.string().required("* Description is required"),
    // .min(8, "* Password must be 8 digits long"),
  });
  if (Tab == "support") {
    validationSchema = Yup.object().shape({
      title: Yup.string().required("* Title is required"),
      // .min(8, "* Password must be 8 digits long"),
    });
  }
  return (
    <Modal className="fade" show={StateData.basicModal}>
      <Formik
        initialValues={{
          title: StateData?.record?.title || "",
          decription: StateData?.record?.decription || "",
          image_url: StateData?.record?.image_url || "",
          file: "",
        }}
        onSubmit={(values) => {
          let formData = new FormData();
          formData.append("title", values.title);
          if (Tab !== "support") {
            formData.append("decription", values.decription);
          }
          formData.append("image_url", values.image_url);
          formData.append("file", values.file);

          // Confirmation Modal
          swal({
            text: "Are you sure you want to do this?",
            buttons: true,
          }).then((willUpdate) => {
            if (willUpdate) {
              updatePage(StateData?.record?.id, formData)
                .then((response) => {
                  if (response.status === 200) {
                    SetState((st) => ({ ...st, basicModal: false }));
                    GetData(values.title);
                    setNotification("success", "Record Updated");
                  }
                })
                .catch((error) => {
                  error.response.statusText == ""
                    ? setNotification("error", error.response.data.message)
                    : setNotification("error", error.response.statusText);
                });
            }
          });
        }}
        // Hooks up our validationSchema to Formik
        validationSchema={validationSchema}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          } = props;
          return (
            <>
              <form action="" onSubmit={handleSubmit}>
                <Modal.Header>
                  <Modal.Title>Edit Content</Modal.Title>
                  <Button
                    variant=""
                    className="close"
                    onClick={() =>
                      SetState((st) => ({ ...st, basicModal: false }))
                    }
                  >
                    <span>&times;</span>
                  </Button>
                </Modal.Header>
                <Modal.Body>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label>Title</label>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        placeholder="Type here"
                        value={values.title}
                        onChange={handleChange}
                      />
                      <div
                        id="val-username1-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.title && errors.title}
                      </div>
                    </div>
                    {StateData?.record?.tab !== "faq" &&
                      StateData?.record?.tab !== "onboard issues" && (
                        <div className="form-group col-md-12">
                          <label>Description</label>
                          <textarea
                            className="form-control"
                            value={values.decription}
                            name="decription"
                            onChange={handleChange}
                            rows="4"
                          />
                          <div
                            id="val-username1-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.decription && errors.decription}
                          </div>
                        </div>
                      )}

                    <div className="input-group col-md-12">
                      <div className="custom-file">
                        <input
                          name="file"
                          type="file"
                          className="custom-file-input"
                          // values={values.image_url}
                          onChange={(event) => {
                            setFieldValue("file", event.currentTarget.files[0]);
                          }}
                        />
                        <label className="custom-file-label">
                          {values.file ? values.file.name : "Choose file"}
                        </label>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    onClick={() =>
                      SetState((st) => ({ ...st, basicModal: false }))
                    }
                    variant="danger light btn-sm"
                  >
                    Close
                  </Button>
                  {/* <input
              variant="primary btn-sm"
              type="submit"
              value="Save changes"
            /> */}
                  <input
                    type="submit"
                    value="Save changes"
                    className="btn btn-primary btn-sm"
                  />
                </Modal.Footer>
              </form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ParentModal;
