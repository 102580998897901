import React, { useCallback, useEffect, useState, useRef } from "react";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import MUIDataTable from "mui-datatables";
import Switch from "@material-ui/core/Switch";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreatAccountModal from "../../components/modals/CreateUserModal";
import swal from "sweetalert";
// Api Services
import {
  getUser,
  getSingleUser,
  updateUser,
  deleteUser,
} from "../../services/user";

const Users = () => {
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState([]);
  const [status, setStatus] = useState(false);
  const [userModal, setUsersModal] = useState(false);
  var token = localStorage.getItem("token");
  var id = localStorage.getItem("id");
  var userRole = localStorage.getItem("role");
  // Check User Role
  if (userRole != 1) {
    history.push("/");
  }
  // Get
  const getCall = () => {
    getUser(id, token)
      .then((response) => {
        setUsers(response.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  // Update
  const updateCall = (event, id) => {
    const values = {
      isActive: event,
    };
    updateUser(id, values, token)
      .then((response) => {
        if (response.data.success === true) {
          getCall();
          setStatus(true);
          if (response.data.data.isActive === true) {
            notify("success", "User Account Activate");
          } else {
            notify("success", "User Account DeActivate");
          }
        }
      })
      .catch((error) => {
        error.response.statusText == ""
          ? notify(error.response.data.data.message)
          : notify(error.response.statusText);
      });
  };
  // Delete
  const deleteCall = (id) => {
    deleteUser(id, token)
      .then((response) => {
        if (response.data.success === true) {
          getCall();
          setStatus(true);
          if (response.data.data.affected === 1) {
            notify("success", "User Account Deleted");
          }
        }
      })
      .catch((error) => {
        error.response.statusText == ""
          ? notify(error.response.data.data.message)
          : notify(error.response.statusText);
      });
  };

  // On Edit
  const onEdit = (id) => {
    getSingleUser(id, token)
      .then((response) => {
        setUser(response.data.data);
        setUsersModal(true);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  useEffect(() => {
    getCall();
  }, []);
  const handleChange = (event, i) => {
    updateCall(event.target.checked, i);
  };
  // Notification
  const notify = (status, text) => {
    if (status == "success") {
      toast.success(`✅ ${text}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else if (status == "error") {
      toast.error(`❌ ${text} !`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
    }
  };
  // Table Colums
  const columns = [
    {
      name: "id",
      label: "#",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowIndex + 1;
        },
      },
    },
    {
      name: "userName",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "accountType",
      label: "Account Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "socialType",
      label: "Social Account",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "roleId",
      label: "Role",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value == 1) {
            return <div>Administator</div>;
          } else if (value == 2) {
            return <div>User</div>;
          } else if (value == 3) {
            return <div>Content Manager</div>;
          } else {
            return <div>User</div>;
          }
        },
      },
    },
    {
      name: "isActive",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Switch
              checked={value}
              name="isActive"
              color="primary"
              onChange={(e) => handleChange(e, tableMeta.rowData[0])}
            />
          );
        },
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Button
                className="btn btn-primary shadow btn-xs sharp mr-1"
                onClick={() => onEdit(value)}
              >
                <i className="fa fa-pencil"></i>
              </Button>
              <Button
                className="btn btn-danger shadow btn-xs sharp"
                onClick={() =>
                  swal({
                    title: "Are you sure?",
                    text:
                      "Once deleted, you will not be able to recover this user!",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                  }).then((willDelete) => {
                    if (willDelete) {
                      deleteCall(value);
                    }
                  })
                }
              >
                <i className="fa fa-trash"></i>
              </Button>
            </>
          );
        },
      },
    },
  ];
  const options = {
    filter: false,
    // filterType: "dropdown",
    download: false,
    viewColumns: false,
    print: false,
    responsive: "standard",
    tableBodyHeight: "100%",
    selectableRows: false,
    fixedHeader: false,
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* User Modal */}
      {/* Create New Account Modal */}
      <CreatAccountModal
        userModal={userModal}
        setUserModal={setUsersModal}
        setNotification={notify}
        getData={getCall}
        userData={user}
      />
      {users.length > 0 ? (
        <>
          <div className="form-row">
            <div className="form-group col-md-12">
              <Button
                className="mr-2 btn-rounded"
                variant="primary"
                onClick={() => {
                  setUsersModal(true);
                  setUser([]);
                }}
              >
                Add New Account
              </Button>
            </div>
          </div>

          <MUIDataTable
            title={"Users"}
            data={users}
            columns={columns}
            options={options}
          />
        </>
      ) : (
        <div className="row">
          <Spinner animation="border" className="m-auto" />
        </div>
      )}
    </>
  );
};
export default Users;
