import React from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import swal from "sweetalert";

// Api Services
import { updatePageContent, createAlertContent, updateAlertContent } from "../../services/page_content";

const DetailModal = ({
  StateData,
  SetState,
  GetData,
  GetOtherData,
  Tab,
  setNotification,
}) => {
  // Schema for yup
  const validationSchema = Yup.object().shape({
    value: Yup.string().required("* Value is required"),
  });

  var initialValues = "";

  // InitialValues

  if (StateData?.cardType) {
    initialValues = {
      id: StateData?.record?.id,
      value: StateData?.record?.value || "",
      type: StateData?.cardType,
    };
  } else {
    initialValues = {
      value: StateData?.record ? StateData?.record?.value : "",
      file: "",
      url: StateData?.record ? StateData?.record?.url : "",
      flag: "",
    };
  }

  return (
    <Modal className="fade" show={StateData.detailModal}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          if (StateData?.cardType) {
            axios
              .post(
                process.env.REACT_APP_BACKEND_URL +
                `support/updateissuecontent`,
                values
              )
              .then((response) => {
                if (response.status === 200) {
                  SetState((st) => ({ ...st, detailModal: false }));
                  GetOtherData(StateData.activeTab, values.type, values.id);
                  setNotification("success", "Record Updated");
                }
              })
              .catch((error) => {
                error.response.statusText == ""
                  ? setNotification("error", error.response.data.message)
                  : setNotification("error", error.response.statusText);
              });
          } else {
            if (StateData?.record) {
              let formData = new FormData();
              formData.append("value", values.value);
              formData.append("file", values.file);
              formData.append("url", values.url);
              formData.append("flag", values.flag);
              if (StateData?.activeTab == StateData?.data[3]["title"]) {
                // Confirmation Modal
                swal({
                  text: "Are you sure you want to do this?",
                  buttons: true,
                }).then((willUpdate) => {
                  if (willUpdate) {
                    updateAlertContent(StateData?.record?.id, formData)
                      .then((response) => {
                        if (response.status === 200) {
                          SetState((st) => ({ ...st, detailModal: false }));
                          GetData(StateData.activeTab);
                          setNotification("success", "Record Updated");
                        }
                      })
                      .catch((error) => {
                        error.response.statusText == ""
                          ? setNotification("error", error.response.data.message)
                          : setNotification("error", error.response.statusText);
                      });
                  }
                });
              } else {
                // Confirmation Modal
                swal({
                  text: "Are you sure you want to do this?",
                  buttons: true,
                }).then((willUpdate) => {
                  if (willUpdate) {
                    updatePageContent(StateData?.record?.id, formData)
                      .then((response) => {
                        if (response.status === 200) {
                          SetState((st) => ({ ...st, detailModal: false }));
                          GetData(StateData.activeTab);
                          setNotification("success", "Record Updated");
                        }
                      })
                      .catch((error) => {
                        error.response.statusText == ""
                          ? setNotification("error", error.response.data.message)
                          : setNotification("error", error.response.statusText);
                      });
                  }
                });
              }

            } else {
              const pageId = StateData.data.filter((obj) => obj.title === StateData.activeTab);
              let formData = new FormData();
              formData.append("value", values.value);
              formData.append("file", values.file);
              formData.append("pagesId", pageId[0].id);
              // Confirmation Modal
              swal({
                text: "Are you sure you want to do this?",
                buttons: true,
              }).then((willUpdate) => {
                if (willUpdate) {
                  createAlertContent(formData)
                    .then((response) => {
                      if (response.status === 200) {
                        SetState((st) => ({ ...st, detailModal: false }));
                        GetData(StateData.activeTab);
                        setNotification("success", "Record Updated");
                      }
                    })
                    .catch((error) => {
                      error.response.statusText == ""
                        ? setNotification("error", error.response.data.message)
                        : setNotification("error", error.response.statusText);
                    });
                }
              });
            }

          }
        }}
        // Hooks up our validationSchema to Formik
        validationSchema={validationSchema}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          } = props;
          return (
            <>
              <form action="" onSubmit={handleSubmit}>
                <Modal.Header>
                  <Modal.Title>{StateData?.record ? "Edit Content" : "Add Content"}</Modal.Title>
                  <Button
                    variant=""
                    className="close"
                    onClick={() =>
                      SetState((st) => ({ ...st, detailModal: false }))
                    }
                  >
                    <span>&times;</span>
                  </Button>
                </Modal.Header>
                <Modal.Body>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label>{StateData?.record?.key}</label>
                      {StateData?.record?.key == "paragraph_description" ? (
                        <textarea
                          className="form-control"
                          value={values.value}
                          name="value"
                          onChange={handleChange}
                          rows="8"
                        />
                      ) : StateData?.record?.key == "image" ||
                        StateData?.record?.key == "png" ||
                        StateData?.record?.key == "jpg" ||
                        StateData?.record?.key == "jpeg" ||
                        StateData?.record?.key == "svg" ||
                        StateData?.record?.key == "gif" ||
                        StateData?.record?.key == "pdf" ||
                        StateData?.record?.key == "doc" ||
                        StateData?.record?.key == "mp4" ? (
                        StateData?.record?.key == "pdf" ||
                          StateData?.record?.key == "doc" ||
                          StateData?.record?.key == "mp4" ||
                          StateData?.record?.key == "png" ||
                          StateData?.record?.key == "jpg" ||
                          StateData?.record?.key == "jpeg" ||
                          StateData?.record?.key == "svg" ||
                          StateData?.record?.key == "gif" ? (
                          <>
                            <input
                              type="text"
                              className="form-control"
                              name="value"
                              placeholder="Type here"
                              value={values.value}
                              onChange={handleChange}
                            />
                            <div className="custom-file mt-2">
                              <input
                                name="file"
                                type="file"
                                className="custom-file-input"
                                onChange={(event) => {
                                  setFieldValue(
                                    "file",
                                    event.currentTarget.files[0]
                                  );
                                }}
                              />
                              <label className="custom-file-label">
                                {values.file ? values.file.name : "Choose file"}
                              </label>
                            </div>
                            {StateData.activeTab == StateData?.data[3]["title"] && <div className="custom-control custom-checkbox mt-2">
                              <input
                                name="flag"
                                type="checkbox"
                                className="custom-control-input"
                                id="Resend Notification"
                                onChange={(e) => { setFieldValue("flag", e.target.checked) }}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="Resend Notification"
                              >Resend Notification</label>
                            </div>}
                          </>
                        ) : (
                          <div className="custom-file">
                            <input
                              name="file"
                              type="file"
                              className="custom-file-input"
                              onChange={(event) => {
                                setFieldValue(
                                  "file",
                                  event.currentTarget.files[0]
                                );
                              }}
                            />
                            <label className="custom-file-label">
                              {values.file ? values.file.name : "Choose file"}
                            </label>
                          </div>
                        )
                      ) : (
                        <>
                          <input
                            type="text"
                            className="form-control"
                            name="value"
                            placeholder="Type here"
                            value={values.value}
                            onChange={handleChange}
                          />
                          {
                            StateData?.activeTab == "Alerts" &&
                            <div className="custom-file mt-2">
                              <input
                                name="file"
                                type="file"
                                className="custom-file-input"
                                onChange={(event) => {
                                  setFieldValue(
                                    "file",
                                    event.currentTarget.files[0]
                                  );
                                }}
                              />
                              <label className="custom-file-label">
                                {values.file ? values.file.name : "Choose file"}
                              </label>
                            </div>
                          }
                        </>
                      )}
                    </div>
                    <div
                      id="val-username1-error"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    >
                      {errors.value && errors.value}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    onClick={() =>
                      SetState((st) => ({ ...st, detailModal: false }))
                    }
                    variant="danger light btn-sm"
                  >
                    Close
                  </Button>
                  <input
                    type="submit"
                    value="Save changes"
                    className="btn btn-primary btn-sm"
                  />
                </Modal.Footer>
              </form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default DetailModal;
