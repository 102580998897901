import React from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";

const CardModal = ({ StateData, SetState, GetData, setNotification }) => {
  var initialValues = "";

  // InitialValues

  if (StateData.cardType == "fuel") {
    initialValues = {
      id: StateData?.record?.id,
      value: StateData?.record?.fueltype || "",
      type: "fuel",
    };
  } else if (StateData.cardType == "issues") {
    initialValues = {
      id: StateData?.record?.id,
      value: StateData?.record?.issuestype || "",
      type: "issue",
    };
  } else {
    initialValues = {
      id: StateData?.record?.id,
      value: StateData?.record?.value || "",
      type: "issuecontent",
    };
  }

  // Schema for yup
  const validationSchema = Yup.object().shape({
    value: Yup.string().required("* Value is required"),
  });

  return (
    <Modal className="fade" show={StateData.cardModal}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          axios
            .post(
              process.env.REACT_APP_BACKEND_URL + `support/updateissuecontent`,
              values
            )
            .then((response) => {
              if (response.status === 200) {
                SetState((st) => ({ ...st, cardModal: false }));
                GetData(StateData.activeTab, values.type, values.id);
                setNotification("success", "Record Updated");
              }
            })
            .catch((error) => {
              error.response.statusText == ""
                ? setNotification("error", error.response.data.message)
                : setNotification("error", error.response.statusText);
            });
        }}
        // Hooks up our validationSchema to Formik
        validationSchema={validationSchema}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          } = props;
          return (
            <>
              <form action="" onSubmit={handleSubmit}>
                <Modal.Header>
                  <Modal.Title>Edit Content</Modal.Title>
                  <Button
                    variant=""
                    className="close"
                    onClick={() =>
                      SetState((st) => ({ ...st, cardModal: false }))
                    }
                  >
                    <span>&times;</span>
                  </Button>
                </Modal.Header>
                <Modal.Body>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label>{StateData?.cardType.toUpperCase()}</label>
                      <input
                        type="text"
                        className="form-control"
                        name="value"
                        placeholder="Type here"
                        value={values.value}
                        onChange={handleChange}
                      />
                    </div>
                    <div
                      id="val-username1-error"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    >
                      {errors.value && errors.value}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    onClick={() =>
                      SetState((st) => ({ ...st, cardModal: false }))
                    }
                    variant="danger light btn-sm"
                  >
                    Close
                  </Button>
                  <input
                    type="submit"
                    value="Save changes"
                    className="btn btn-primary btn-sm"
                  />
                </Modal.Footer>
              </form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default CardModal;
