import Axios from "axios";
let API_URL = process.env.REACT_APP_BACKEND_URL;

export const getPage = (tab) => {
  return Axios.get(API_URL + `page/${tab}`);
};

export const createPage = (values) => {
  return Axios.post(API_URL + "page/create", values);
};

export const updatePage = (id, values) => {
  return Axios.put(API_URL + `page/update/${id}`, values);
};

export const deletePage = (id) => {
  return Axios.put(API_URL + `page/delete/${id}`);
};
