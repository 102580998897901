import Axios from "axios";
let API_URL = process.env.REACT_APP_BACKEND_URL;

export const getPageContent = (tab) => {
  return Axios.get(API_URL + `pagecontent/${tab}`);
};

export const createPageContent = (values) => {
  return Axios.post(API_URL + "pagecontent/create", values);
};

// create For alert Tab
export const createAlertContent = (values) => {
  return Axios.post(API_URL + "support/createAlert", values);
};

// Edit for alert Tab
export const updateAlertContent = (id, values) => {
  return Axios.put(API_URL + `support/editAlert/${id}`, values);
};
export const updatePageContent = (id, values) => {
  return Axios.put(API_URL + `pagecontent/update/${id}`, values);
};

export const deletePageContent = (id) => {
  return Axios.put(API_URL + `pagecontent/delete/${id}`);
};
