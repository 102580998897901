import React, { useCallback, useEffect, useState, useRef } from "react";
import { Card, Tab, Nav, Modal, Button } from "react-bootstrap";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";
import axios from "axios";

const ProcedureModal = ({
  StateData,
  SetState,
  GetData,
  GetOtherData,
  setNotification,
}) => {
  const [contentType, setContentType] = useState([]);
  // Update Content Arrray After Add Or Remove New Fields
  const updateContentType = (index, value) => {
    if (contentType[index]) {
      if (contentType[index].id === index) {
        let newContent = [...contentType];
        newContent[index] = { id: index, value: value };
        setContentType(newContent);
      } else {
        setContentType((oldArray) => [
          ...oldArray,
          { id: index, value: value },
        ]);
      }
    } else {
      setContentType((oldArray) => [...oldArray, { id: index, value: value }]);
    }
  };
  // Remove Content Array
  const removeContentType = (index) => {
    setContentType([
      ...contentType.slice(0, index),
      ...contentType.slice(index + 1),
    ]);
  };
  // Schema for yup
  const validationSchema = Yup.object().shape({
    contentData: Yup.array()
      .of(
        Yup.object().shape({
          key: Yup.string().required("Type is Required"),
          value: Yup.string().required("Value is Required"),
        })
      )
      .required("Required"),
  });

  return (
    <Modal className="fade" show={StateData.addProcedureModal} size="lg">
      <Formik
        initialValues={{
          fuelType: StateData.selectedFuel || "",
          issueType: StateData.selectedIssue || "",
          contentData: [
            {
              key: "",
              value: "",
            },
          ],
        }}
        onSubmit={(values) => {
          axios
            .post(
              process.env.REACT_APP_BACKEND_URL + `support/createIssuecontent`,
              values
            )
            .then((response) => {
              if (response) {
                SetState((st) => ({
                  ...st,
                  addProcedureModal: false,
                }));
                GetOtherData("", "issuecontent", StateData.selectedIssue);
                setNotification("success", "Record Added");
              }
            })
            .catch((error) => {
              error.response.statusText == ""
                ? setNotification("error", error.response.data.data.message)
                : setNotification("error", error.response.statusText);
            });
        }}
        // Hooks up our validationSchema to Formik
        validationSchema={validationSchema}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          } = props;
          return (
            <>
              <form action="" onSubmit={handleSubmit}>
                <Modal.Header>
                  <Modal.Title>Add Content</Modal.Title>
                  <Button
                    variant=""
                    className="close"
                    onClick={() => {
                      SetState((st) => ({ ...st, addProcedureModal: false }));
                      setContentType([]);
                    }}
                  >
                    <span>&times;</span>
                  </Button>
                </Modal.Header>
                <Modal.Body>
                  <FieldArray
                    name="contentData"
                    render={({ insert, remove, push }) => (
                      <>
                        {values.contentData
                          ? values.contentData.map((content, index) => (
                              <div className="form-row" key={index}>
                                <div className="form-group col-md-12">
                                  <div className="form-group mb-0">
                                    <label className="radio-inline mr-3">
                                      <input
                                        type="radio"
                                        name={`contentData.${index}.key`}
                                        value="heading"
                                        onChange={handleChange}
                                        onClick={(event) =>
                                          updateContentType(
                                            index,
                                            event.target.value
                                          )
                                        }
                                      />{" "}
                                      Heading
                                    </label>
                                    <label className="radio-inline mr-3">
                                      <input
                                        type="radio"
                                        name={`contentData.${index}.key`}
                                        value="heading_green"
                                        onChange={handleChange}
                                        onClick={(event) =>
                                          updateContentType(
                                            index,
                                            event.target.value
                                          )
                                        }
                                      />{" "}
                                      Heading Green
                                    </label>
                                    <label className="radio-inline mr-3">
                                      <input
                                        type="radio"
                                        name={`contentData.${index}.key`}
                                        value="heading_blue"
                                        onChange={handleChange}
                                        onClick={(event) =>
                                          updateContentType(
                                            index,
                                            event.target.value
                                          )
                                        }
                                      />{" "}
                                      Heading Blue
                                    </label>
                                    <label className="radio-inline mr-3">
                                      <input
                                        type="radio"
                                        name={`contentData.${index}.key`}
                                        value="heading_black"
                                        onChange={handleChange}
                                        onClick={(event) =>
                                          updateContentType(
                                            index,
                                            event.target.value
                                          )
                                        }
                                      />{" "}
                                      Heading Black
                                    </label>
                                    <label className="radio-inline mr-3">
                                      <input
                                        type="radio"
                                        name={`contentData.${index}.key`}
                                        value="paragraph_description"
                                        onChange={handleChange}
                                        onClick={(event) =>
                                          updateContentType(
                                            index,
                                            event.target.value
                                          )
                                        }
                                      />{" "}
                                      Paragraph Description
                                    </label>
                                    <label className="radio-inline mr-3">
                                      <input
                                        type="radio"
                                        name={`contentData.${index}.key`}
                                        value="check_description"
                                        onChange={handleChange}
                                        onClick={(event) =>
                                          updateContentType(
                                            index,
                                            event.target.value
                                          )
                                        }
                                      />{" "}
                                      Check Description
                                    </label>
                                    <label className="radio-inline mr-3">
                                      <input
                                        type="radio"
                                        name={`contentData.${index}.key`}
                                        value="Bullet_description"
                                        onChange={handleChange}
                                        onClick={(event) =>
                                          updateContentType(
                                            index,
                                            event.target.value
                                          )
                                        }
                                      />{" "}
                                      Bullet Description
                                    </label>
                                    <label className="radio-inline mr-3">
                                      <input
                                        type="radio"
                                        name={`contentData.${index}.key`}
                                        value="warning"
                                        onChange={handleChange}
                                        onClick={(event) =>
                                          updateContentType(
                                            index,
                                            event.target.value
                                          )
                                        }
                                      />{" "}
                                      Warning
                                    </label>
                                    {index > 0 && (
                                      <Button
                                        className="mr-2"
                                        variant="danger btn-rounded float-right btn-xs"
                                        onClick={() => {
                                          remove(index);
                                          removeContentType(index);
                                        }}
                                      >
                                        <span className="btn-icon-left text-danger">
                                          <i className="fa fa-close color-info" />
                                        </span>
                                        Remove
                                      </Button>
                                    )}
                                  </div>
                                  <div
                                    id="val-username1-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.contentData &&
                                      errors?.contentData[index]?.key &&
                                      errors.contentData[index].key}
                                  </div>
                                </div>
                                <div className="form-group col-md-12">
                                  <label>Value</label>
                                  {contentType[index]?.value ==
                                    "paragraph_description" ||
                                  contentType[index]?.value == "warning" ||
                                  contentType[index]?.value ==
                                    "Bullet_description" ? (
                                    <textarea
                                      className="form-control"
                                      name={`contentData.${index}.value`}
                                      value={content["value"]}
                                      onChange={handleChange}
                                      rows="4"
                                    />
                                  ) : (
                                    <input
                                      type="text"
                                      className="form-control"
                                      name={`contentData.${index}.value`}
                                      placeholder="Type here"
                                      value={content["value"]}
                                      onChange={handleChange}
                                    />
                                  )}

                                  <div
                                    id="val-username1-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.contentData &&
                                      errors?.contentData[index]?.value &&
                                      errors.contentData[index].value}
                                  </div>
                                </div>
                              </div>
                            ))
                          : ""}
                        <div className="col-sm-12 mb-3">
                          <Button
                            className="mr-2"
                            variant="info btn-rounded float-right btn-xs"
                            onClick={() =>
                              push({
                                key: "",
                                value: "",
                              })
                            }
                          >
                            <span className="btn-icon-left text-info">
                              <i className="fa fa-plus color-info" />
                            </span>
                            Add
                          </Button>
                        </div>
                      </>
                    )}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    onClick={() => {
                      SetState((st) => ({ ...st, addProcedureModal: false }));
                      setContentType([]);
                    }}
                    variant="danger light btn-sm"
                  >
                    Close
                  </Button>
                  <input
                    type="submit"
                    value="Save changes"
                    className="btn btn-primary btn-sm"
                  />
                </Modal.Footer>
              </form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ProcedureModal;
