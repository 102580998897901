import React, { useCallback, useEffect, useState } from "react";
import { Card, Tab, Nav, Modal, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomTable from "../../../components/customTable";
import Spinner from "react-bootstrap/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Api Services
import { getPartner, updatePartner } from "../../../services/partners";

// Table Head
const tableHeader = ["#", "Name", "Link", "Image", "Action"];

const Partners = () => {
  const [state, setState] = useState({
    basicModal: false,
    data: [],
  });

  // Get
  const getCall = () => {
    getPartner().then((response) => {
      const data = response.data.data;
      setState((st) => ({
        ...st,
        data: data,
      }));
    });
  };

  useEffect(() => {
    getCall();
  }, []);
  const setRecord = useCallback((record) => {
    setState((st) => ({ ...st, record, basicModal: true }));
  }, []);

  // Schema for yup
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("* Name is required"),
    link: Yup.string().required("* Link is required"),
    // .min(8, "* Password must be 8 digits long"),
  });

  // Notification
  const notify = (status, text) => {
    if (status == "success") {
      toast.success(`✅ ${text}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else if (status == "error") {
      toast.error(`❌ ${text} !`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
    }
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Modal className="fade" show={state.basicModal}>
        <Formik
          initialValues={{
            name: state?.record?.name || "",
            link: state?.record?.link || "",
            image_url: state?.record?.image_url || "",
            file: "",
          }}
          onSubmit={(values) => {
            let formData = new FormData();
            formData.append("name", values.name);
            formData.append("link", values.link);
            formData.append("image_url", values.image_url);
            formData.append("file", values.file);
            updatePartner(state?.record?.id, formData)
              .then((response) => {
                if (response.data.success === true) {
                  setState((st) => ({ ...st, basicModal: false }));
                  getCall();
                  notify("success", "Record Updated");
                }
              })
              .catch((error) => {
                error.response.statusText == ""
                  ? notify("error", error.response.data.data.message)
                  : notify("error", error.response.statusText);
              });
          }}
          // Hooks up our validationSchema to Formik
          validationSchema={validationSchema}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            } = props;
            return (
              <>
                <form action="" onSubmit={handleSubmit}>
                  <Modal.Header>
                    <Modal.Title>Edit Content</Modal.Title>
                    <Button
                      variant=""
                      className="close"
                      onClick={() =>
                        setState((st) => ({ ...st, basicModal: false }))
                      }
                    >
                      <span>&times;</span>
                    </Button>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label>Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          placeholder="Type here"
                          value={values.name}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <label>Link</label>
                        <textarea
                          className="form-control"
                          value={values.link}
                          name="link"
                          onChange={handleChange}
                          rows="4"
                        />
                      </div>
                      <div className="input-group col-md-12">
                        <div className="custom-file">
                          <input
                            name="file"
                            type="file"
                            className="custom-file-input"
                            onChange={(event) => {
                              setFieldValue(
                                "file",
                                event.currentTarget.files[0]
                              );
                            }}
                          />
                          <label className="custom-file-label">
                            {values.file ? values.file.name : "Choose file"}
                          </label>
                        </div>
                        <input
                          type="hidden"
                          name="image_url"
                          value={values.image_url}
                        />
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      onClick={() =>
                        setState((st) => ({ ...st, basicModal: false }))
                      }
                      variant="danger light btn-sm"
                    >
                      Close
                    </Button>
                    <input
                      type="submit"
                      value="Save changes"
                      className="btn btn-primary btn-sm"
                    />
                  </Modal.Footer>
                </form>
              </>
            );
          }}
        </Formik>
      </Modal>
      {state?.data.length > 0 ? (
        <Card>
          <Card.Header>
            <Card.Title>Edit Content</Card.Title>
          </Card.Header>
          <Card.Body>
            <CustomTable
              data={state?.data}
              headers={tableHeader}
              onEdit={setRecord}
              section="Aderco"
            />
          </Card.Body>
        </Card>
      ) : (
        <div className="row">
          <Spinner animation="border" className="m-auto" />
        </div>
      )}
    </>
  );
};
export default Partners;
