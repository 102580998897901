import React, { useCallback, useEffect, useState, useRef } from "react";
import { Card, Tab, Nav, Modal, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { createUser, updateUser } from "./../../services/user";
import swal from "sweetalert";

const ParentModal = ({
  userModal,
  setUserModal,
  setNotification,
  getData,
  userData,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  var token = localStorage.getItem("token");

  // Schema for yup
  var validationSchema;
  var initialValues;
  if (userData?.id) {
    if (userData?.password) {
      validationSchema = Yup.object().shape({
        userName: Yup.string()
          .min(2, "*User Name must have at least 2 characters")
          .max(100, "*User Name can't be longer than 100 characters")
          .required("*User Name is required"),
        email: Yup.string()
          .email("*Must be a valid email address")
          .max(100, "*Email must be less than 100 characters")
          .required("*Email is required"),
        roleId: Yup.string().required("*Role is required"),
      });
      initialValues = {
        userName: userData?.userName ? userData.userName : "",
        email: userData?.email ? userData.email : "",
        roleId: userData?.roleId ? userData.roleId : "",
      };
    } else {
      validationSchema = Yup.object().shape({
        userName: Yup.string()
          .min(2, "*User Name must have at least 2 characters")
          .max(100, "*User Name can't be longer than 100 characters")
          .required("*User Name is required"),
        email: Yup.string()
          .email("*Must be a valid email address")
          .max(100, "*Email must be less than 100 characters")
          .required("*Email is required"),
        password: Yup.string()
          .min(8, "*Password atleast have 8 characters")
          .required("*Password is required"),
        roleId: Yup.string().required("*Role is required"),
      });
      initialValues = {
        userName: userData?.userName ? userData.userName : "",
        email: userData?.email ? userData.email : "",
        roleId: userData?.roleId ? userData.roleId : "",
        password: "",
      };
    }
  } else {
    validationSchema = Yup.object().shape({
      userName: Yup.string()
        .min(2, "*User Name must have at least 2 characters")
        .max(100, "*User Name can't be longer than 100 characters")
        .required("*User Name is required"),
      email: Yup.string()
        .email("*Must be a valid email address")
        .max(100, "*Email must be less than 100 characters")
        .required("*Email is required"),
      password: Yup.string()
        .min(8, "*Password atleast have 8 characters")
        .required("*Password is required"),
      roleId: Yup.string().required("*Role is required"),
    });
    initialValues = {
      userName: "",
      email: "",
      password: "",
      roleId: "",
      accountType: "email",
    };
  }

  return (
    <Modal className="fade bd-example-modal-lg" show={userModal} size="lg">
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          if (userData?.id) {
            // Confirmation Modal
            swal({
              text: "Are you sure you want to do this?",
              buttons: true,
            }).then((willUpdate) => {
              if (willUpdate) {
                updateUser(userData?.id, values, token)
                  .then((response) => {
                    if (response.data.success === true) {
                      setUserModal(false);
                      getData();
                      setNotification("success", "User Updated");
                    } else {
                      setNotification("error", response.data.data.message);
                    }
                  })
                  .catch((error) => {
                    error.response.statusText == ""
                      ? setNotification(
                          "error",
                          error.response.data.data.message
                        )
                      : setNotification("error", error.response.statusText);
                  });
              }
            });
          } else {
            createUser(values)
              .then((response) => {
                if (response.data.success === true) {
                  setUserModal(false);
                  getData();
                  setNotification("success", "Account Created");
                } else {
                  setNotification("error", response.data.data.message);
                }
              })
              .catch((error) => {
                error.response.statusText == ""
                  ? setNotification("error", error.response.data.data.message)
                  : setNotification("error", error.response.statusText);
              });
          }
        }}
        // Hooks up our validationSchema to Formik
        validationSchema={validationSchema}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          } = props;
          return (
            <>
              <form action="" onSubmit={handleSubmit}>
                <Modal.Header>
                  <Modal.Title>Create New Account</Modal.Title>
                  <Button
                    variant=""
                    className="close"
                    onClick={() => setUserModal(false)}
                  >
                    <span>&times;</span>
                  </Button>
                </Modal.Header>
                <Modal.Body>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label>UserName</label>
                      <input
                        type="text"
                        className="form-control"
                        name="userName"
                        placeholder="Enter User Name"
                        value={values.userName}
                        onChange={handleChange}
                        autocomplete="off"
                      />
                      <div
                        id="val-username1-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.userName && errors.userName}
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <label>Email</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder="Enter Email"
                        value={values.email}
                        onChange={handleChange}
                        disabled={userData?.email && true}
                        autocomplete="off"
                      />
                      <div
                        id="val-username1-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.email && errors.email}
                      </div>
                    </div>
                    {(userData.length == 0 || userData.password === null) && (
                      <div className="form-group col-md-6">
                        <label className="text-label">Create Password</label>
                        <div className="input-group transparent-append">
                          <input
                            type={`${showPassword ? "text" : "password"}`}
                            className="form-control"
                            id="val-password1"
                            name="password"
                            onChange={handleChange}
                            value={values.password}
                            placeholder="Enter Password"
                            autocomplete="off"
                          />

                          <div
                            className="input-group-append show-pass"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            <span className="input-group-text">
                              {" "}
                              <i className="fa fa-eye-slash" />
                            </span>
                          </div>
                          <div
                            id="val-username1-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.password && errors.password}
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="form-group col-md-6">
                      <label>Role</label>
                      <select
                        defaultValue={
                          userData?.roleId ? userData.roleId : "default"
                        }
                        id="inputState"
                        className="form-control"
                        name="roleId"
                        onChange={handleChange}
                      >
                        <option value="default" disabled>
                          Select Role
                        </option>
                        <option value="1">Administrator</option>
                        <option value="3">Content Manager</option>
                        <option value="2">User</option>
                      </select>
                      <div
                        id="val-username1-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.roleId && errors.roleId}
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    onClick={() => setUserModal(false)}
                    variant="danger light btn-sm"
                  >
                    Close
                  </Button>
                  <input
                    type="submit"
                    value="Save changes"
                    className="btn btn-primary btn-sm"
                  />
                </Modal.Footer>
              </form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ParentModal;
