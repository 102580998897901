import React, { useCallback, useEffect, useState, Fragment } from "react";
import { Card, Tab, Nav, Col, ListGroup, Button } from "react-bootstrap";
import axios from "axios";
import CustomTable from "../../../components/customTable";
import DetailsTable from "../../../components/customTable/dragDropTable";
import ParentModal from "../../../components/modals/ParentModal";
import DetailModal from "../../../components/modals/DetailModal";
import AddContentModal from "../../../components/modals/AddContentModal";
import AddProcedureModal from "../../../components/modals/ProcedureModal";
import CardModal from "../../../components/modals/CardsModal";
import Spinner from "react-bootstrap/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Api Services
import { getPage, deletePage } from "../../../services/pages";
import { deletePageContent } from "../../../services/page_content";

// Table Head
const tableHeader = ["#", "Title", "Image", "Action"];
const tableDetailsHeader = ["#", "Key", "Value", "Action"];

const Issues = () => {
  const [currentTab, setCurrentTab] = useState(false);
  const [state, setState] = useState({
    activeTab: "",
    basicModal: false,
    detailModal: false,
    data: [],
    addContentModal: false,
    // Curative Treatment
    addProcedureModal: false,
    cardModal: false,
    curativeTreatment: [],
    issueTypes: [],
    issueContent: [],
    selectedFuel: "",
    selectedIssue: "",
  });

  // Get call
  const getCall = (tab) => {
    getPage("onboard issues").then((response) => {
      const data = response.data;
      if (tab) {
        setCurrentTab(tab);
        setState((st) => ({
          ...st,
          activeTab: tab,
          data: data,
        }));
      } else {
        setCurrentTab(data[0].title);
        setState((st) => ({
          ...st,
          activeTab: data[0].title,
          data: data,
        }));
      }
    });
  };

  // Get Call For Curative Treatment
  const getCallCurativeTreatment = (tab, type, id) => {
    axios
      .get(process.env.REACT_APP_BACKEND_URL + `support/showcontent`)
      .then((response) => {
        const data = response.data.data;
        setState((st) => ({
          ...st,
          curativeTreatment: data,
        }));

        // update issue card
        if (type == "issue") {
          data.map(
            (row, i) =>
              row.id === state.selectedFuel &&
              setState((st) => ({
                ...st,
                issueTypes: row.issuetypes,
              }))
          );
        }
        // update issue content
        if (type == "issuecontent") {
          data.map(
            (row, i) =>
              row.id === state.selectedFuel &&
              row.issuetypes.map(
                (subrow, j) =>
                  subrow.id === state.selectedIssue &&
                  setState((st) => ({
                    ...st,
                    issueContent: subrow.issueContent,
                    selectedIssue: id,
                  }))
              )
          );
        }
      });
  };

  // Delete
  const deleteCall = (type, id) => {
    if (type == "page") {
      deletePage(id)
        .then((response) => {
          if (response.status === 200) {
            getCall();
            notify("success", "Record Deleted");
          }
        })
        .catch((error) => {
          error.response.statusText == ""
            ? notify(error.response.data.message)
            : notify(error.response.statusText);
        });
    }
    if (type == "pageContent") {
      deletePageContent(id)
        .then((response) => {
          if (response.status === 200) {
            getCall(state.activeTab);
            notify("success", "Record Deleted");
          }
        })
        .catch((error) => {
          error.response.statusText == ""
            ? notify(error.response.data.message)
            : notify(error.response.statusText);
        });
    }

    if (type == "issueContent") {
      axios
        .put(
          process.env.REACT_APP_BACKEND_URL + `support/deleteIssuecontent/` + id
        )
        .then((response) => {
          if (response.status === 200) {
            getCallCurativeTreatment(
              state.activeTab,
              "issuecontent",
              state.selectedIssue
            );
            notify("success", "Record Deleted");
          }
        })
        .catch((error) => {
          error.response.statusText == ""
            ? notify(error.response.data.message)
            : notify(error.response.statusText);
        });
    }
  };

  // Update Drag & Drop
  const updateCall = (data, oldIndex, newIndex) => {
    let body = {
      data: data,
      oldIndex: oldIndex,
      newIndex: newIndex,
    };
    axios
      .put(process.env.REACT_APP_BACKEND_URL + `support/sortdata`, body)
      .then((response) => {
        if (response.data.success === true) {
          getCall();
          notify("success", "Record Updated");
        }
      })
      .catch((error) => {
        error.response.statusText == ""
          ? notify(error.response.data.data.message)
          : notify(error.response.statusText);
      });
  };

  useEffect(() => {
    getCall();
  }, []);

  // Get Curative Treatment
  useEffect(() => {
    if (state.data.length > 0) {
      if (state.activeTab == state.data[2]["title"]) {
        getCallCurativeTreatment();
      } else {
        setState((st) => ({
          ...st,
          curativeTreatment: [],
          issueTypes: [],
          issueContent: [],
          selectedFuel: "",
          selectedIssue: "",
        }));
      }
    }
  }, [currentTab]);

  const setRecord = useCallback((record) => {
    setState((st) => ({ ...st, record, basicModal: true }));
  }, []);

  const setRecordDetail = useCallback((record, cardType) => {
    if (record.key == "folder") {
      setState((st) => ({ ...st, record, addContentModal: true }));
    } else {
      setState((st) => ({ ...st, record, cardType, detailModal: true }));
    }

  }, []);

  const setCardDetail = useCallback((record, cardType) => {
    setState((st) => ({ ...st, record, cardType, cardModal: true }));
  }, []);

  // Filter Card Based on Selection
  const filterFn = (type, id) => {
    if (type == "fuel") {
      state.curativeTreatment.map(
        (data, i) =>
          data.id === id &&
          setState((st) => ({
            ...st,
            issueTypes: data.issuetypes,
            selectedFuel: id,
          }))
      );
    }

    if (type == "issues") {
      state.issueTypes.map(
        (data, i) =>
          data.id === id &&
          setState((st) => ({
            ...st,
            issueContent: data.issueContent,
            selectedIssue: id,
          }))
      );
    }
    // Reset Other Card if Fuel Type Change
    if (type == "fuel" && state.issueContent.length > 0) {
      setState((st) => ({
        ...st,
        issueContent: [],
        selectedIssue: "",
      }));
    }
  };
  const filterData = state.data.filter((obj) => obj.title === state.activeTab);

  // Notification
  const notify = (status, text) => {
    if (status == "success") {
      toast.success(`✅ ${text}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else if (status == "error") {
      toast.error(`❌ ${text} !`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
    }
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Parent Modal */}
      <ParentModal
        StateData={state}
        SetState={setState}
        GetData={getCall}
        Tab="support"
        setNotification={notify}
      />
      {/* Details Modal */}
      <DetailModal
        StateData={state}
        SetState={setState}
        GetData={getCall}
        GetOtherData={getCallCurativeTreatment}
        Tab="support"
        setNotification={notify}
      />

      {/* Add New Content */}
      <AddContentModal
        StateData={state}
        SetState={setState}
        GetData={getCall}
        setNotification={notify}
        Tab="support"
      />

      {/* Card Modal */}
      <CardModal
        StateData={state}
        SetState={setState}
        GetData={getCallCurativeTreatment}
        setNotification={notify}
      />
      {/* Add New Procedure */}
      <AddProcedureModal
        StateData={state}
        SetState={setState}
        GetData={getCall}
        GetOtherData={getCallCurativeTreatment}
        setNotification={notify}
      />
      {state.activeTab === currentTab ? (
        <Card>
          <Card.Header>
            <Card.Title>{state.activeTab} Content</Card.Title>
          </Card.Header>
          <Card.Body>
            <div className="custom-tab-1">
              <Tab.Container defaultActiveKey={state.activeTab}>
                <Nav as="ul" className="nav-tabs">
                  {state?.data?.map((tab, i) => (
                    <Nav.Item as="li" key={i}>
                      <Nav.Link
                        onClick={() => {
                          setCurrentTab(tab.title);
                          setState((st) => ({
                            ...st,
                            activeTab: tab.title,
                          }));
                        }}
                        eventKey={tab.title}
                      >
                        {tab.title}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
                <Tab.Content activeTab={state.activeTab} className="pt-4">
                  {filterData?.map((page) => (
                    <Tab.Pane
                      eventKey={page.title}
                      key={page.id}
                      tabId={page.id}
                    >
                      <CustomTable
                        data={filterData}
                        headers={tableHeader}
                        onEdit={setRecord}
                      />
                      {page.title == state.data[2]["title"] && (
                        <Card>
                          <Card.Header>
                            <Card.Title>Engine Details</Card.Title>
                          </Card.Header>
                          <Card.Body>
                            <CustomTable
                              headers={tableDetailsHeader}
                              onEdit={setRecordDetail}
                              detailsTable={filterData[0]["pageContent"].sort(
                                (a, b) => a.sortId - b.sortId
                              )}
                            />
                          </Card.Body>
                        </Card>
                      )}
                      <Card>
                        <Card.Header>
                          <Card.Title>
                            {page.title == state.data[2]["title"]
                              ? "Fuel Details"
                              : "Details"}
                          </Card.Title>
                        </Card.Header>
                        <Card.Body>
                          {page.title == state.data[0]["title"] ? (
                            <Col xl="12">
                              <Button
                                className="mr-2"
                                variant="primary btn-rounded float-right btn-sm"
                                onClick={() =>
                                  setState((st) => ({
                                    ...st,
                                    record: "",
                                    addContentModal: true,
                                  }))
                                }
                              >
                                <span className="btn-icon-left text-primary">
                                  <i className="fa fa-plus color-primary" />
                                </span>
                                Add
                              </Button>
                              <DetailsTable
                                headers={tableDetailsHeader}
                                onUpdate={updateCall}
                                onDelete={deleteCall}
                                onEdit={setRecordDetail}
                                detailsTable={filterData[0]["pageContent"].sort(
                                  (a, b) => a.sortId - b.sortId
                                )}
                              />
                            </Col>
                          ) : page.title == state.data[2]["title"] ? (
                            <div className="row">
                              <Col xl="3">
                                <Card className="h-auto">
                                  <Card.Header>
                                    <Card.Title>Fuel</Card.Title>
                                  </Card.Header>
                                  <Card.Body className="p-0">
                                    <div className="basic-list-group">
                                      <ListGroup>
                                        {state.curativeTreatment.map(
                                          (fuelData, i) => (
                                            <Fragment key={i}>
                                              <ListGroup.Item
                                                className="d-flex justify-content-between align-items-center"
                                                action
                                                active={
                                                  state.selectedFuel ===
                                                    fuelData.id
                                                    ? true
                                                    : false
                                                }
                                                onClick={() =>
                                                  filterFn("fuel", fuelData.id)
                                                }
                                              >
                                                {fuelData.fueltype}
                                                <Button
                                                  className="btn btn-primary shadow btn-xs sharp mr-1"
                                                  onClick={() =>
                                                    setCardDetail(
                                                      fuelData,
                                                      "fuel"
                                                    )
                                                  }
                                                >
                                                  <i className="fa fa-pencil"></i>
                                                </Button>
                                              </ListGroup.Item>
                                            </Fragment>
                                          )
                                        )}
                                      </ListGroup>
                                    </div>
                                  </Card.Body>
                                </Card>
                              </Col>
                              {state.issueTypes.length > 0 && (
                                <Col xl="3">
                                  <Card className="h-auto">
                                    <Card.Header>
                                      <Card.Title>Issues</Card.Title>
                                    </Card.Header>
                                    <Card.Body className="p-0">
                                      <div className="basic-list-group">
                                        <ListGroup>
                                          {state.issueTypes.map((data, i) => (
                                            <Fragment key={i}>
                                              <ListGroup.Item
                                                className="d-flex justify-content-between align-items-center"
                                                action
                                                active={
                                                  state.selectedIssue ===
                                                    data.id
                                                    ? true
                                                    : false
                                                }
                                                onClick={() => {
                                                  filterFn(
                                                    "issues",
                                                    data.id,
                                                    data.issuestype
                                                  );
                                                }}
                                              >
                                                {data.issuestype}
                                                <Button
                                                  className="btn btn-primary shadow btn-xs sharp mr-1"
                                                  onClick={() =>
                                                    setCardDetail(
                                                      data,
                                                      "issues"
                                                    )
                                                  }
                                                >
                                                  <i className="fa fa-pencil"></i>
                                                </Button>
                                              </ListGroup.Item>
                                            </Fragment>
                                          ))}
                                        </ListGroup>
                                      </div>
                                    </Card.Body>
                                  </Card>
                                </Col>
                              )}
                              {state.issueContent.length > 0 && (
                                <Col xl="12">
                                  <Button
                                    className="mr-2"
                                    variant="primary btn-rounded float-right btn-sm"
                                    onClick={() =>
                                      setState((st) => ({
                                        ...st,
                                        addProcedureModal: true,
                                      }))
                                    }
                                  >
                                    <span className="btn-icon-left text-primary">
                                      <i className="fa fa-plus color-primary" />
                                    </span>
                                    Add
                                  </Button>
                                  <CustomTable
                                    headers={tableDetailsHeader}
                                    onDelete={deleteCall}
                                    onEdit={setRecordDetail}
                                    detailsTable={state.issueContent.sort(
                                      (a, b) => a.sortId - b.sortId
                                    )}
                                    table="issuecontent"
                                  />
                                </Col>
                              )}
                            </div>
                          ) : state.activeTab === state.data[3]["title"] ?
                            <Col xl="12">
                              <Button
                                className="mr-2"
                                variant="primary btn-rounded float-right btn-sm"
                                onClick={() =>
                                  setState((st) => ({
                                    ...st,
                                    detailModal: true,
                                  }))
                                }
                              >
                                <span className="btn-icon-left text-primary">
                                  <i className="fa fa-plus color-primary" />
                                </span>
                                Add
                              </Button>
                              <CustomTable
                                headers={tableDetailsHeader}
                                onDelete={deleteCall}
                                onEdit={setRecordDetail}
                                detailsTable={filterData[0]["pageContent"].sort(
                                  (a, b) => a.sortId - b.sortId
                                )}
                              />
                            </Col>
                            :
                            <CustomTable
                              headers={tableDetailsHeader}
                              onEdit={setRecordDetail}
                              detailsTable={filterData[0]["pageContent"].sort(
                                (a, b) => a.sortId - b.sortId
                              )}
                            />
                          }
                        </Card.Body>
                      </Card>
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Tab.Container>
            </div>
          </Card.Body>
        </Card>
      ) : (
        <div className="row">
          <Spinner animation="border" className="m-auto" />
        </div>
      )}
    </>
  );
};
export default Issues;
