import React from "react";
import { Table, Button } from "react-bootstrap";
import ReactPlayer from "react-player";
import swal from "sweetalert";

const ContactTable = ({ onDelete, onEdit, data, headers, pageName }) => {
  return (
    <Table responsive>
      <thead>
        <tr>
          {headers.map((title) => (
            <th key={title}>
              <strong>{title}</strong>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {pageName == "contact" &&
          data?.map((row, index) => (
            <tr key={row.id}>
              <th scope="row">{index + 1}</th>
              <td>{row.title}</td>
              <td>{row.name}</td>
              <td>{row.rank}</td>
              <td>{row.email}</td>
              <td>{row.phone}</td>
              <td>{row.address}</td>
              <td>{row.addressDetails}</td>
              <td>
                <Button
                  className="btn btn-primary shadow btn-xs sharp mr-1"
                  onClick={() => onEdit(row)}
                >
                  <i className="fa fa-pencil"></i>
                </Button>
                <Button
                  className="btn btn-danger shadow btn-xs sharp"
                  onClick={() =>
                    swal({
                      title: "Are you sure?",
                      text:
                        "Once deleted, you will not be able to recover this file!",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then((willDelete) => {
                      if (willDelete) {
                        onDelete(row.id);
                      }
                    })
                  }
                >
                  <i className="fa fa-trash"></i>
                </Button>
              </td>
            </tr>
          ))}
        {pageName == "videos" &&
          data?.map((row, index) => (
            <tr key={row.id}>
              <th scope="row">{index + 1}</th>
              <td>{row.title}</td>
              <td>
                <ReactPlayer
                  width="360px"
                  height="100%"
                  controls={true}
                  light={true}
                  url={row.video}
                  config={{
                    file: { attributes: { controlsList: "nodownload" } },
                  }}
                />
              </td>
              <td>
                <Button
                  className="btn btn-primary shadow btn-xs sharp mr-1"
                  onClick={() => onEdit(row)}
                >
                  <i className="fa fa-pencil"></i>
                </Button>
                <Button
                  className="btn btn-danger shadow btn-xs sharp"
                  onClick={() =>
                    swal({
                      title: "Are you sure?",
                      text:
                        "Once deleted, you will not be able to recover this file!",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then((willDelete) => {
                      if (willDelete) {
                        onDelete(row.id, row.video);
                      }
                    })
                  }
                >
                  <i className="fa fa-trash"></i>
                </Button>
              </td>
            </tr>
          ))}
        {pageName == "greenometer" &&
          data?.map((row, index) => (
            <tr key={row.id}>
              <th scope="row">{index + 1}</th>
              <td>{row.key}</td>
              {row.key == "image" ? (
                <td>
                  <img
                    src={row.value}
                    className="rounded-lg ml-2"
                    width="100"
                    alt=""
                  />
                </td>
              ) : (
                <td>{row.value}</td>
              )}

              <td>
                <Button
                  className="btn btn-primary shadow btn-xs sharp mr-1"
                  onClick={() => onEdit(row)}
                >
                  <i className="fa fa-pencil"></i>
                </Button>
              </td>
            </tr>
          ))}
        {pageName == "greenometerFormula" &&
          data?.map((row, index) => (
            <tr key={row.id}>
              <th scope="row">{index + 1}</th>
              <td>{row.year}</td>
              <td>{row.totalTrees}</td>
              <td>
                <Button
                  className="btn btn-primary shadow btn-xs sharp mr-1"
                  onClick={() => onEdit(row)}
                >
                  <i className="fa fa-pencil"></i>
                </Button>
                <Button
                  className="btn btn-danger shadow btn-xs sharp"
                  onClick={() =>
                    swal({
                      title: "Are you sure?",
                      text:
                        "Once deleted, you will not be able to recover this file!",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then((willDelete) => {
                      if (willDelete) {
                        onDelete(row.id);
                      }
                    })
                  }
                >
                  <i className="fa fa-trash"></i>
                </Button>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};
export default ContactTable;
