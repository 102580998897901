import React, { useCallback, useEffect, useState, useRef } from "react";
import { Card, Tab, Nav, Modal, Button } from "react-bootstrap";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { createFaq, updateFaq } from "../../services/contact";

const ContactModal = ({ StateData, SetState, GetData, setNotification }) => {
  const [contentType, setContentType] = useState([]);
  // Update Content Arrray After Add Or Remove New Fields
  const updateContentType = (index, value) => {
    if (contentType[index]) {
      if (contentType[index].id === index) {
        let newContent = [...contentType];
        newContent[index] = { id: index, value: value };
        setContentType(newContent);
      } else {
        setContentType((oldArray) => [
          ...oldArray,
          { id: index, value: value },
        ]);
      }
    } else {
      setContentType((oldArray) => [...oldArray, { id: index, value: value }]);
    }
  };
  // Remove Content Array
  const removeContentType = (index) => {
    setContentType([
      ...contentType.slice(0, index),
      ...contentType.slice(index + 1),
    ]);
  };
  // Schema for yup
  var validationSchema = "";
  // Initialize Default Values
  var initialValues = "";
  if (StateData.pageId == "") {
    validationSchema = Yup.object().shape({
      title: Yup.string().required("* Question is required"),
      contentData: Yup.array()
        .of(
          Yup.object().shape({
            key: Yup.string().required("Type is Required"),
            value: Yup.string().required("Answer is Required"),
          })
        )
        .required("Required"),
    });

    initialValues = {
      title: "",
      image_url: "",
      tab: "faq",
      contentData: [
        {
          key: "",
          value: "",
        },
      ],
    };
  } else {
    validationSchema = validationSchema = Yup.object().shape({
      contentData: Yup.array()
        .of(
          Yup.object().shape({
            key: Yup.string().required("Type is Required"),
            value: Yup.string().required("Answer is Required"),
          })
        )
        .required("Required"),
    });
    initialValues = {
      pagesId: StateData.pageId || "",
      contentData: [
        {
          key: "",
          value: "",
        },
      ],
    };
  }
  return (
    <Modal className="fade" show={StateData.addContentModal} size="lg">
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          if (StateData.pagesId == "") {
            let formData = new FormData();
            formData.append("title", values.title);
            formData.append("image_url", values.image_url);
            formData.append("tab", values.tab);
            formData.append("contentData", JSON.stringify(values.contentData));

            axios
              .post(
                process.env.REACT_APP_BACKEND_URL + `support/createFaq`,
                formData
              )
              .then((response) => {
                if (response) {
                  SetState((st) => ({ ...st, addContentModal: false }));
                  GetData(values.type);
                  setNotification("success", "Record Added");
                }
              })
              .catch((error) => {
                error.response.statusText == ""
                  ? setNotification("error", error.response.data.data.message)
                  : setNotification("error", error.response.statusText);
              });
          } else {
            axios
              .post(
                process.env.REACT_APP_BACKEND_URL + `support/createFaqContent`,
                values
              )
              .then((response) => {
                if (response) {
                  SetState((st) => ({
                    ...st,
                    pageId: "",
                    addContentModal: false,
                  }));
                  GetData(values.type);
                  setNotification("success", "Record Added");
                }
              })
              .catch((error) => {
                error.response.statusText == ""
                  ? setNotification("error", error.response.data.data.message)
                  : setNotification("error", error.response.statusText);
              });
          }
        }}
        // Hooks up our validationSchema to Formik
        validationSchema={validationSchema}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          } = props;
          return (
            <>
              <form action="" onSubmit={handleSubmit}>
                <Modal.Header>
                  <Modal.Title>
                    {StateData?.pageId == "" ? "Add" : "Add Content"}
                  </Modal.Title>
                  <Button
                    variant=""
                    className="close"
                    onClick={() => {
                      SetState((st) => ({ ...st, addContentModal: false }));
                      setContentType([]);
                    }}
                  >
                    <span>&times;</span>
                  </Button>
                </Modal.Header>
                <Modal.Body>
                  {StateData.pageId == "" && (
                    <>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label>Question</label>
                          <input
                            type="text"
                            className="form-control"
                            name="title"
                            placeholder="Type here"
                            value={values.title}
                            onChange={handleChange}
                          />
                          <div
                            id="val-username1-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.title && errors.title}
                          </div>
                        </div>
                        <div className="form-group col-md-6">
                          <label>Image</label>
                          <div className="custom-file">
                            <input
                              type="file"
                              className="custom-file-input"
                              // values={values.image_url}
                              onChange={(event) => {
                                setFieldValue(
                                  "image_url",
                                  event.currentTarget.files[0]
                                );
                              }}
                            />
                            <label className="custom-file-label">
                              {values.image_url
                                ? values.image_url.name
                                : "Choose file"}
                            </label>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </>
                  )}
                  <FieldArray
                    name="contentData"
                    render={({ insert, remove, push }) => (
                      <>
                        {values.contentData
                          ? values.contentData.map((content, index) => (
                              <div className="form-row" key={index}>
                                <div className="form-group col-md-12">
                                  <div className="form-group mb-0">
                                    <label className="radio-inline mr-3">
                                      <input
                                        type="radio"
                                        name={`contentData.${index}.key`}
                                        value="paragraph_description"
                                        onChange={handleChange}
                                        onClick={(event) =>
                                          updateContentType(
                                            index,
                                            event.target.value
                                          )
                                        }
                                      />{" "}
                                      Paragraph Description
                                    </label>
                                    <label className="radio-inline mr-3">
                                      <input
                                        type="radio"
                                        name={`contentData.${index}.key`}
                                        value="check_description"
                                        onChange={handleChange}
                                        onClick={(event) =>
                                          updateContentType(
                                            index,
                                            event.target.value
                                          )
                                        }
                                      />{" "}
                                      Check Description
                                    </label>
                                    {index > 0 && (
                                      <Button
                                        className="mr-2"
                                        variant="danger btn-rounded float-right btn-xs"
                                        onClick={() => {
                                          remove(index);
                                          removeContentType(index);
                                        }}
                                      >
                                        <span className="btn-icon-left text-danger">
                                          <i className="fa fa-close color-info" />
                                        </span>
                                        Remove
                                      </Button>
                                    )}
                                  </div>
                                  <div
                                    id="val-username1-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.contentData &&
                                      errors?.contentData[index]?.key &&
                                      errors.contentData[index].key}
                                  </div>
                                </div>
                                <div className="form-group col-md-12">
                                  <label>Answer</label>
                                  {contentType[index]?.value ==
                                  "paragraph_description" ? (
                                    <textarea
                                      className="form-control"
                                      name={`contentData.${index}.value`}
                                      value={content["value"]}
                                      onChange={handleChange}
                                      rows="4"
                                    />
                                  ) : (
                                    <input
                                      type="text"
                                      className="form-control"
                                      name={`contentData.${index}.value`}
                                      placeholder="Type here"
                                      value={content["value"]}
                                      onChange={handleChange}
                                    />
                                  )}

                                  <div
                                    id="val-username1-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.contentData &&
                                      errors?.contentData[index]?.value &&
                                      errors.contentData[index].value}
                                  </div>
                                </div>
                              </div>
                            ))
                          : ""}
                        <div className="col-sm-12 mb-3">
                          <Button
                            className="mr-2"
                            variant="info btn-rounded float-right btn-xs"
                            onClick={() =>
                              push({
                                key: "",
                                value: "",
                              })
                            }
                          >
                            <span className="btn-icon-left text-info">
                              <i className="fa fa-plus color-info" />
                            </span>
                            Add
                          </Button>
                        </div>
                      </>
                    )}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    onClick={() => {
                      SetState((st) => ({ ...st, addContentModal: false }));
                      setContentType([]);
                    }}
                    variant="danger light btn-sm"
                  >
                    Close
                  </Button>
                  <input
                    type="submit"
                    value="Save changes"
                    className="btn btn-primary btn-sm"
                  />
                </Modal.Footer>
              </form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ContactModal;
