import React from "react";
import { Table, Button } from "react-bootstrap";
import swal from "sweetalert";

const CustomTable = ({
  onDelete,
  onEdit,
  data,
  headers,
  detailsTable,
  table,
  section,
}) => {
  return (
    <Table responsive>
      <thead>
        <tr>
          {headers.map((title) => (
            <th key={title}>
              <strong>{title}</strong>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {detailsTable
          ? detailsTable.map(
            (row, index) =>
              (row.group_id === 0 || table == "issuecontent") && (
                <>
                  <tr key={row.id}>
                    <th scope="row">{index + 1}</th>
                    <td>
                      {row.key == "folder" ? (
                        <i className="fa fa-folder"></i>
                      ) : (
                        row.key
                      )}
                    </td>
                    {row.key == "image" ? (
                      <img
                        src={row.value}
                        className="rounded-lg mr-2"
                        width="100"
                        alt=""
                      />
                    ) : (
                      <td
                        dangerouslySetInnerHTML={{ __html: row.value }}
                      ></td>
                    )}
                    <td>
                      <Button
                        className="btn btn-primary shadow btn-xs sharp mr-1"
                        onClick={() => onEdit(row, table)}
                      >
                        <i className="fa fa-pencil"></i>
                      </Button>
                      {onDelete && (
                        <Button
                          className="btn btn-danger shadow btn-xs sharp"
                          onClick={() =>
                            swal({
                              title: "Are you sure?",
                              text:
                                "Once deleted, you will not be able to recover this file!",
                              icon: "warning",
                              buttons: true,
                              dangerMode: true,
                            }).then((willDelete) => {
                              if (willDelete) {
                                if (table == "issuecontent") {
                                  onDelete("issueContent", row.id);
                                } else {
                                  onDelete("pageContent", row.id);
                                }
                              }
                            })
                          }
                        >
                          <i className="fa fa-trash"></i>
                        </Button>
                      )}
                    </td>
                  </tr>
                  {/* Child */}
                  {detailsTable?.map(
                    (rowSubData, i) =>
                      (row.id == rowSubData.group_id ||
                        row.id == rowSubData.groupid) && (
                        <>
                          <tr>
                            <th
                              scope="row"
                              style={{ borderTop: "none" }}
                            ></th>
                            <td style={{ borderTop: "none" }}>
                              {rowSubData.key}
                            </td>

                            {rowSubData.key == "image" ? (
                              <td>
                                <img
                                  src={rowSubData.value}
                                  className="rounded-lg ml-2"
                                  width="100"
                                  alt=""
                                />
                              </td>
                            ) : rowSubData.key == "pdf" ||
                              rowSubData.key == "doc" ||
                              "mp4" ? (
                              <td>
                                <a href={rowSubData.url} target="_blank">
                                  {rowSubData.value}
                                </a>
                              </td>
                            ) : (
                              <td
                                dangerouslySetInnerHTML={{
                                  __html: rowSubData.value,
                                }}
                                style={{ borderTop: "none" }}
                              ></td>
                            )}
                            <td style={{ borderTop: "none" }}>
                              <Button
                                className="btn btn-primary shadow btn-xs sharp mr-1"
                                onClick={() => onEdit(rowSubData, table)}
                              >
                                <i className="fa fa-pencil"></i>
                              </Button>
                              {onDelete && (
                                <Button
                                  className="btn btn-danger shadow btn-xs sharp"
                                  onClick={() =>
                                    swal({
                                      title: "Are you sure?",
                                      text:
                                        "Once deleted, you will not be able to recover this file!",
                                      icon: "warning",
                                      buttons: true,
                                      dangerMode: true,
                                    }).then((willDelete) => {
                                      if (willDelete) {
                                        if (table == "issuecontent") {
                                          onDelete(
                                            "issueContent",
                                            rowSubData.id
                                          );
                                        } else {
                                          onDelete(
                                            "pageContent",
                                            rowSubData.id
                                          );
                                        }
                                      }
                                    })
                                  }
                                >
                                  <i className="fa fa-trash"></i>
                                </Button>
                              )}
                            </td>
                          </tr>
                          {/* Sub-Child */}
                          {detailsTable?.map(
                            (subData, j) =>
                              rowSubData.id == subData.group_id && (
                                <tr>
                                  <th
                                    scope="row"
                                    style={{ borderTop: "none" }}
                                  ></th>
                                  <td style={{ borderTop: "none" }}>
                                    {subData.key}
                                  </td>

                                  {subData.key == "image" ? (
                                    <td>
                                      <img
                                        src={subData.value}
                                        className="rounded-lg ml-2"
                                        width="100"
                                        alt=""
                                      />
                                    </td>
                                  ) : subData.key == "pdf" ||
                                    subData.key == "doc" ? (
                                    <td>
                                      <a href={subData.link} target="_blank">
                                        {subData.value}
                                      </a>
                                    </td>
                                  ) : (
                                    <td
                                      dangerouslySetInnerHTML={{
                                        __html: subData.value,
                                      }}
                                      style={{ borderTop: "none" }}
                                    ></td>
                                  )}
                                  <td style={{ borderTop: "none" }}>
                                    <Button
                                      className="btn btn-primary shadow btn-xs sharp mr-1"
                                      onClick={() => onEdit(subData, table)}
                                    >
                                      <i className="fa fa-pencil"></i>
                                    </Button>

                                    {onDelete && (
                                      <Button
                                        className="btn btn-danger shadow btn-xs sharp"
                                        onClick={() =>
                                          swal({
                                            title: "Are you sure?",
                                            text:
                                              "Once deleted, you will not be able to recover this file!",
                                            icon: "warning",
                                            buttons: true,
                                            dangerMode: true,
                                          }).then((willDelete) => {
                                            if (willDelete) {
                                              if (table == "issuecontent") {
                                                onDelete(
                                                  "issueContent",
                                                  subData.id
                                                );
                                              } else {
                                                onDelete(
                                                  "pageContent",
                                                  subData.id
                                                );
                                              }
                                            }
                                          })
                                        }
                                      >
                                        <i className="fa fa-trash"></i>
                                      </Button>
                                    )}
                                  </td>
                                </tr>
                              )
                          )}
                        </>
                      )
                  )}
                </>
              )
          )
          : data.map((row, index) =>
            row.tab ? (
              <tr key={row.id}>
                <th scope="row">{index + 1}</th>
                <td>{row.title}</td>
                {row.decription && (
                  <td
                    dangerouslySetInnerHTML={{ __html: row.decription }}
                  ></td>
                )}
                <td>
                  <img
                    src={row.image_url}
                    className="rounded-lg mr-2"
                    width="100"
                    alt=""
                  />
                </td>
                <td>
                  <Button
                    className="btn btn-primary shadow btn-xs sharp mr-1"
                    onClick={() => onEdit(row)}
                  >
                    <i className="fa fa-pencil"></i>
                  </Button>
                  {onDelete && (
                    <Button
                      className="btn btn-danger shadow btn-xs sharp"
                      onClick={() =>
                        swal({
                          title: "Are you sure?",
                          text:
                            "Once deleted, you will not be able to recover this file!",
                          icon: "warning",
                          buttons: true,
                          dangerMode: true,
                        }).then((willDelete) => {
                          if (willDelete) {
                            onDelete("page", row.id);
                          }
                        })
                      }
                    >
                      <i className="fa fa-trash"></i>
                    </Button>
                  )}
                </td>
              </tr>
            ) : (
              <tr key={row.id}>
                <th scope="row">{index + 1}</th>
                <td>{row.name}</td>
                <td dangerouslySetInnerHTML={{ __html: row.link }}></td>
                <td>
                  <img
                    src={row.image_url}
                    className="rounded-lg mr-2"
                    width="100"
                    alt=""
                  />
                </td>
                <td>
                  <Button
                    className="btn btn-primary shadow btn-xs sharp mr-1"
                    onClick={() => onEdit(row)}
                  >
                    <i className="fa fa-pencil"></i>
                  </Button>
                </td>
              </tr>
            )
          )}
      </tbody>
    </Table>
  );
};
export default CustomTable;
