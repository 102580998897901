import React, { useCallback, useEffect, useState } from "react";
import { Card, Tab, Nav, Modal, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import Table from "../../../components/customTable/table";
import Spinner from "react-bootstrap/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getVideo,
  createVideo,
  updateVideo,
  deleteVideo,
} from "../../../services/videos";

const tableHeader = ["#", "Title", "Video", "Action"];

const Videos = () => {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    basicModal: false,
    data: [],
  });
  // Get
  const getCall = () => {
    getVideo().then((response) => {
      const data = response.data.data;
      setState((st) => ({
        ...st,
        data: data,
      }));
    });
  };

  // Delete
  const deleteCall = (id, url) => {
    const values = {
      video: url,
    };
    deleteVideo(id, values)
      .then((response) => {
        if (response.status === 200) {
          getCall();
          notify("success", "Recored Deleted");
        }
      })
      .catch((error) => {
        error.response.statusText == ""
          ? notify(error.response.data.data.message)
          : notify(error.response.statusText);
      });
  };
  useEffect(() => {
    getCall();
  }, []);
  // Schema for yup
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("* Title is required"),
  });
  const setRecord = useCallback((record) => {
    setState((st) => ({ ...st, record, basicModal: true }));
  }, []);
  // Notification
  const notify = (status, text) => {
    if (status == "success") {
      toast.success(`✅ ${text}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else if (status == "error") {
      toast.error(`❌ ${text} !`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
    }
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Modal className="fade" show={state.basicModal}>
        <Formik
          initialValues={{
            title: state?.record?.title || "",
            video: state?.record?.video || "",
            file: "",
          }}
          onSubmit={(values) => {
            setLoading(true);
            let formData = new FormData();
            formData.append("title", values.title);
            formData.append("video", values.video);
            formData.append("file", values.file);

            if (state?.record?.id) {
              // update
              updateVideo(state?.record?.id, formData)
                .then((response) => {
                  if (response.data.success === true) {
                    setLoading(false);
                    setState((st) => ({ ...st, basicModal: false }));
                    getCall();
                    notify("success", "Record Updated");
                  }
                })
                .catch((error) => {
                  error.response.statusText == ""
                    ? notify("error", error.response.data.data.message)
                    : notify("error", error.response.statusText);
                });
            } else {
              // create
              createVideo(formData)
                .then((response) => {
                  if (response.data.success === true) {
                    setLoading(false);
                    setState((st) => ({ ...st, basicModal: false }));
                    getCall();
                    notify("success", "Record Created");
                  }
                })
                .catch((error) => {
                  error.response.statusText == ""
                    ? notify("error", error.response.data.data.message)
                    : notify("error", error.response.statusText);
                });
            }
          }}
          // Hooks up our validationSchema to Formik
          validationSchema={validationSchema}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            } = props;
            return (
              <>
                <form action="" onSubmit={handleSubmit}>
                  <Modal.Header>
                    <Modal.Title>
                      {state?.record?.id ? "Edit Video" : "Add Video"}
                    </Modal.Title>
                    <Button
                      variant=""
                      className="close"
                      onClick={() =>
                        setState((st) => ({ ...st, basicModal: false }))
                      }
                    >
                      <span>&times;</span>
                    </Button>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="form-row">
                      {loading === true && (
                        <Spinner
                          animation="grow"
                          variant="secondary"
                          className="m-auto"
                          aria-hidden="false"
                        />
                      )}
                      <div className="form-group col-md-12">
                        <label>Title</label>
                        <input
                          type="text"
                          className="form-control"
                          name="title"
                          placeholder="Type here"
                          value={values.title}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="input-group col-md-12">
                        <div className="custom-file">
                          <input
                            name="file"
                            type="file"
                            className="custom-file-input"
                            onChange={(event) => {
                              setFieldValue(
                                "file",
                                event.currentTarget.files[0]
                              );
                            }}
                          />
                          <label className="custom-file-label">
                            {values.file ? values.file.name : "Choose file"}
                          </label>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      onClick={() =>
                        setState((st) => ({ ...st, basicModal: false }))
                      }
                      variant="danger light btn-sm"
                    >
                      Close
                    </Button>
                    <input
                      type="submit"
                      value="Save changes"
                      className="btn btn-primary btn-sm"
                    />
                  </Modal.Footer>
                </form>
              </>
            );
          }}
        </Formik>
      </Modal>
      <div className="form-row">
        <div className="form-group col-md-12">
          <Button
            className="mr-2 btn-rounded"
            variant="primary"
            onClick={() =>
              setState((st) => ({
                ...st,
                record: "",
                basicModal: true,
              }))
            }
          >
            Add Video
          </Button>
        </div>
      </div>
      {state?.data.length > 0 ? (
        <Card>
          <Card.Header>
            <Card.Title>Videos</Card.Title>
          </Card.Header>
          <Card.Body>
            <div className="custom-tab-1">
              <Table
                data={state?.data}
                headers={tableHeader}
                onEdit={setRecord}
                onDelete={deleteCall}
                pageName="videos"
              />
            </div>
          </Card.Body>
        </Card>
      ) : (
        <div className="row">
          <Spinner animation="border" className="m-auto" />
        </div>
      )}
    </>
  );
};
export default Videos;
