import Axios from "axios";
let API_URL = process.env.REACT_APP_BACKEND_URL;

export const getContact = () => {
  return Axios.get(API_URL + `contact/`);
};

export const createContact = (values) => {
  return Axios.post(API_URL + "contact/create", values);
};

export const updateContact = (id, values) => {
  return Axios.put(API_URL + `contact/update/${id}`, values);
};

export const deleteContact = (id) => {
  return Axios.put(API_URL + `contact/delete/${id}`);
};
