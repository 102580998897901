import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { createPageContent, updatePageContent } from "../../services/page_content";

const AddContentModal = ({ StateData, SetState, GetData, setNotification }) => {
    const [contentType, setContentType] = useState([]);
    const [loading, setLoading] = useState(false);
    // Update Content Arrray After Add Or Remove New Fields
    const updateContentType = (index, value) => {
        if (contentType[index]) {
            if (contentType[index].id === index) {
                let newContent = [...contentType];
                newContent[index] = { id: index, value: value };
                setContentType(newContent);
            } else {
                setContentType((oldArray) => [
                    ...oldArray,
                    { id: index, value: value },
                ]);
            }
        } else {
            setContentType((oldArray) => [...oldArray, { id: index, value: value }]);
        }
    };
    // Remove Content Array
    const removeContentType = (index) => {
        setContentType([
            ...contentType.slice(0, index),
            ...contentType.slice(index + 1),
        ]);
    };
    // Schema for yup
    var validationSchema = "";
    // Initialize Default Values
    var initialValues = "";
    if (StateData.pageId == "") {
        validationSchema = Yup.object().shape({
            title: Yup.string().required("* Question is required"),
            contentData: Yup.array()
                .of(
                    Yup.object().shape({
                        key: Yup.string().required("Type is Required"),
                        value: Yup.string().required("Answer is Required"),
                    })
                )
                .required("Required"),
        });

        initialValues = {
            title: "",
            image_url: "",
            tab: "faq",
            contentData: [
                {
                    key: "",
                    value: "",
                },
            ],
        };
    } else {
        validationSchema = Yup.object().shape(StateData?.record?.value ? {
            value: Yup.string().required("Type is Required"),
        } : {
            value: Yup.string().required("Type is Required"),
            contentData: Yup.array()
                .of(
                    Yup.object().shape({
                        key: Yup.string().required("Display name is Required"),
                        value: Yup.string().required("Attachment is Required"),
                        // file: Yup.mixed().required('File is required'),
                    })
                )
                .required("Required"),
        });
        initialValues = {
            pagesId: StateData?.data[0]?.id || "",
            key: "folder",
            value: StateData?.record?.value || "",
            contentData: [
                {
                    key: "",
                    value: "",
                },
            ],
        };
    }
    return (
        <Modal className="fade" show={StateData.addContentModal} size="lg">
            <Formik
                initialValues={initialValues}
                onSubmit={(values) => {
                    if (StateData.pagesId == "") {
                        let formData = new FormData();
                        formData.append("title", values.title);
                        formData.append("image_url", values.image_url);
                        formData.append("tab", values.tab);
                        formData.append("contentData", JSON.stringify(values.contentData));

                        axios
                            .post(
                                process.env.REACT_APP_BACKEND_URL + `support/createFaq`,
                                formData
                            )
                            .then((response) => {
                                if (response) {
                                    SetState((st) => ({ ...st, addContentModal: false }));
                                    GetData(values.type);
                                    setNotification("success", "Record Added");
                                }
                            })
                            .catch((error) => {
                                error.response.statusText == ""
                                    ? setNotification("error", error.response.data.data.message)
                                    : setNotification("error", error.response.statusText);
                            });
                    } else {
                        setLoading(true);
                        let formData = new FormData();
                        for (let i = 0; i <= values.contentData.length - 1; i++) {
                            formData.append("display_name", values.contentData[i].key);
                            formData.append("attachment", values.contentData[i].value);
                        }
                        formData.append("key", values.key);
                        formData.append("value", values.value);
                        formData.append("pagesId", values.pagesId);
                        if (StateData?.record?.id) {
                            updatePageContent(StateData?.record?.id, formData)
                                .then((response) => {
                                    if (response.status === 200) {
                                        setLoading(false);
                                        SetState((st) => ({
                                            ...st,
                                            addContentModal: false,
                                        }));
                                        GetData(StateData?.activeTab);
                                        setNotification("success", "Record Updated");
                                    }
                                })
                                .catch((error) => {
                                    setLoading(false);
                                    error.response.statusText == ""
                                        ? setNotification("error", error.response.data.message)
                                        : setNotification("error", error.response.statusText);
                                });
                        } else {
                            createPageContent(formData)
                                .then((response) => {
                                    if (response.status === 200) {
                                        setLoading(false);
                                        SetState((st) => ({
                                            ...st,
                                            addContentModal: false,
                                        }));
                                        GetData(StateData?.activeTab);
                                        setNotification("success", "Record Added");
                                    }
                                })
                                .catch((error) => {
                                    setLoading(false);
                                    error.response.statusText == ""
                                        ? setNotification("error", error.response.data.message)
                                        : setNotification("error", error.response.statusText);
                                });
                        }

                    }
                }}
                // Hooks up our validationSchema to Formik
                validationSchema={validationSchema}
            >
                {(props) => {
                    const {
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                    } = props;
                    return (
                        <>
                            <form action="" onSubmit={handleSubmit}>
                                <Modal.Header>
                                    <Modal.Title>
                                        {StateData?.record == "" ? "Add Content" : "Edit Content"}
                                    </Modal.Title>
                                    <Button
                                        variant=""
                                        className="close"
                                        onClick={() => {
                                            SetState((st) => ({ ...st, addContentModal: false }));
                                            setContentType([]);
                                        }}
                                    >
                                        <span>&times;</span>
                                    </Button>
                                </Modal.Header>
                                <Modal.Body>
                                    {loading ? <div className="row">
                                        <Spinner animation="border" className="m-auto" />
                                    </div>
                                        :
                                        (<>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label>Folder Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="value"
                                                        placeholder="Type here"
                                                        value={values.value}
                                                        onChange={handleChange}
                                                    />
                                                    <div
                                                        id="val-username1-error"
                                                        className="invalid-feedback animated fadeInUp"
                                                        style={{ display: "block" }}
                                                    >
                                                        {errors.value && errors.value}
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <FieldArray
                                                name="contentData"
                                                render={({ insert, remove, push }) => (
                                                    <>
                                                        {values.contentData
                                                            ? values.contentData.map((content, index) => (
                                                                <div className="form-row" key={index}>
                                                                    <div className="form-group col-md-12">
                                                                        <div className="form-group mb-0">
                                                                            {index > 0 && (
                                                                                <Button
                                                                                    className="mr-2"
                                                                                    variant="danger btn-rounded float-right btn-xs"
                                                                                    onClick={() => {
                                                                                        remove(index);
                                                                                        removeContentType(index);
                                                                                    }}
                                                                                >
                                                                                    <span className="btn-icon-left text-danger">
                                                                                        <i className="fa fa-close color-info" />
                                                                                    </span>
                                                                                    Remove
                                                                                </Button>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group col-md-12">
                                                                        <label>Display Name</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name={`contentData.${index}.key`}
                                                                            placeholder="Type here"
                                                                            value={content["key"]}
                                                                            onChange={handleChange}
                                                                        />
                                                                        <div
                                                                            id="val-username1-error"
                                                                            className="invalid-feedback animated fadeInUp"
                                                                            style={{ display: "block" }}
                                                                        >
                                                                            {errors.contentData &&
                                                                                errors?.contentData[index]?.key &&
                                                                                errors.contentData[index].key}
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group col-md-12">
                                                                        <label>Attachment</label>
                                                                        <div className="custom-file">
                                                                            <input
                                                                                type="file"
                                                                                className="custom-file-input"
                                                                                // values={values.image_url}
                                                                                onChange={(event) => {
                                                                                    setFieldValue(
                                                                                        `contentData.${index}.value`,
                                                                                        event.currentTarget.files[0]
                                                                                    );
                                                                                }}
                                                                            />
                                                                            <label className="custom-file-label">
                                                                                {content.value
                                                                                    ? content.value.name
                                                                                    : "Choose file"}
                                                                            </label>
                                                                        </div>
                                                                        <div
                                                                            id="val-username1-error"
                                                                            className="invalid-feedback animated fadeInUp"
                                                                            style={{ display: "block" }}
                                                                        >
                                                                            {errors.contentData &&
                                                                                errors?.contentData[index]?.value &&
                                                                                errors.contentData[index].value}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                            : ""}
                                                        <div className="col-sm-12 mb-3">
                                                            <Button
                                                                className="mr-2"
                                                                variant="info btn-rounded float-right btn-xs"
                                                                onClick={() =>
                                                                    push({
                                                                        key: "",
                                                                        value: "",
                                                                    })
                                                                }
                                                            >
                                                                <span className="btn-icon-left text-info">
                                                                    <i className="fa fa-plus color-info" />
                                                                </span>
                                                                Add
                                                            </Button>
                                                        </div>
                                                    </>
                                                )}
                                            />
                                        </>)}
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        onClick={() => {
                                            SetState((st) => ({ ...st, addContentModal: false }));
                                            setContentType([]);
                                        }}
                                        variant="danger light btn-sm"
                                    >
                                        Close
                                    </Button>
                                    <input
                                        type="submit"
                                        value="Save changes"
                                        className="btn btn-primary btn-sm"
                                    />
                                </Modal.Footer>
                            </form>
                        </>
                    );
                }}
            </Formik>

        </Modal>
    );
};

export default AddContentModal;
