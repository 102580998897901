import Axios from "axios";
let API_URL = process.env.REACT_APP_BACKEND_URL;

export const getUser = (id, token) => {
  return Axios.get(API_URL + `user/getallusers/${id}`, {
    headers: {
      "x-access-token": token,
    },
  });
};

export const getSingleUser = (id, token) => {
  return Axios.get(API_URL + `user/profile/${id}`, {
    headers: {
      "x-access-token": token,
    },
  });
};

export const createUser = (values) => {
  return Axios.post(API_URL + "auth/sign_up", values);
};

export const updateUser = (id, values, token) => {
  return Axios.put(API_URL + `user/update/${id}`, values, {
    headers: {
      "x-access-token": token,
    },
  });
};

export const deleteUser = (id, token) => {
  return Axios.put(API_URL + `user/delete/${id}`, "", {
    headers: {
      "x-access-token": token,
    },
  });
};
