import React, { useState, useEffect } from "react";
import { Table, Button } from "react-bootstrap";
import swal from "sweetalert";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";

const DragDropTable = ({
  headers,
  onEdit,
  onDelete,
  onUpdate,
  detailsTable,
}) => {
  const DragHandle = sortableHandle(() => (
    <td>
      <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect x="0" y="0" width="24" height="24"></rect>
          <circle fill="#000000" cx="5" cy="12" r="2"></circle>
          <circle fill="#000000" cx="12" cy="12" r="2"></circle>
          <circle fill="#000000" cx="19" cy="12" r="2"></circle>
        </g>
      </svg>
    </td>
  ));
  const SortableItem = sortableElement(
    ({ index, keyValue, Value, rowData }) => (
      <tr key={index}>
        <DragHandle />
        <td>{keyValue}</td>
        <td>{Value}</td>
        <td>
          <Button
            className="btn btn-primary shadow btn-xs sharp mr-1"
            onClick={() => onEdit(rowData)}
          >
            <i className="fa fa-pencil"></i>
          </Button>
          <Button
            className="btn btn-danger shadow btn-xs sharp"
            onClick={() =>
              swal({
                title: "Are you sure?",
                text:
                  "Once deleted, you will not be able to recover this file!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then((willDelete) => {
                if (willDelete) {
                  onDelete("pageContent", rowData.id);
                }
              })
            }
          >
            <i className="fa fa-trash"></i>
          </Button>
        </td>
      </tr>
    )
  );

  const SortableContainer = sortableContainer(({ children }) => {
    return <tbody>{children}</tbody>;
  });
  const [items, setItems] = useState(detailsTable);
  const [oldIndex, setOldIndex] = useState("");
  const [newIndex, setNewIndex] = useState("");

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      setOldIndex(items[oldIndex].sortId);
      setNewIndex(items[newIndex].sortId);
      setItems((items) => arrayMoveImmutable(items, oldIndex, newIndex));
    }
  };
  useEffect(() => {
    if (oldIndex && newIndex) {
      onUpdate(items, oldIndex, newIndex);
    } else {
      setOldIndex("");
      setNewIndex("");
    }
  }, [items]);
  return (
    <Table responsive>
      <thead>
        <tr>
          {headers.map((title) => (
            <th key={title}>
              <strong>{title}</strong>
            </th>
          ))}
        </tr>
      </thead>
      <SortableContainer
        onSortEnd={onSortEnd}
        lockAxis="y"
        disableAutoscroll={false}
        useDragHandle
      >
        {detailsTable?.map(
          (row, index) =>
            row.group_id === 0 && (
              <>
                <tr key={row.id}>
                  <th scope="row">{index + 1}</th>
                  <td>
                    {row.key == "folder" ? (
                      <i className="fa fa-folder"></i>
                    ) : (
                      row.key
                    )}
                  </td>
                  {row.key == "image" ? (
                    <img
                      src={row.value}
                      className="rounded-lg mr-2"
                      width="100"
                      alt=""
                    />
                  ) : (
                    <td dangerouslySetInnerHTML={{ __html: row.value }}></td>
                  )}
                  <td>
                    <Button
                      className="btn btn-primary shadow btn-xs sharp mr-1"
                      onClick={() => onEdit(row)}
                    >
                      <i className="fa fa-pencil"></i>
                    </Button>
                    <Button
                      className="btn btn-danger shadow btn-xs sharp"
                      onClick={() =>
                        swal({
                          title: "Are you sure?",
                          text:
                            "Once deleted, you will not be able to recover this file!",
                          icon: "warning",
                          buttons: true,
                          dangerMode: true,
                        }).then((willDelete) => {
                          if (willDelete) {
                            onDelete("pageContent", row.id);
                          }
                        })
                      }
                    >
                      <i className="fa fa-trash"></i>
                    </Button>
                  </td>
                </tr>
                {detailsTable?.map(
                  (rowSubData, i) =>
                    row.id == rowSubData.group_id && (
                      <SortableItem
                        key={i}
                        index={i}
                        keyValue={rowSubData.key}
                        Value={rowSubData.value}
                        rowData={rowSubData}
                      />
                    )
                )}
              </>
            )
        )}
      </SortableContainer>
    </Table>
  );
};

export default DragDropTable;
