import Axios from "axios";
let API_URL = process.env.REACT_APP_BACKEND_URL;

export const getVideo = () => {
  return Axios.get(API_URL + `video/`);
};

export const createVideo = (values) => {
  return Axios.post(API_URL + "video/create", values);
};

export const updateVideo = (id, values) => {
  return Axios.put(API_URL + `video/update/${id}`, values);
};

export const deleteVideo = (id, values) => {
  return Axios.put(API_URL + `video/delete/${id}`, values);
};
