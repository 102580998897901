import Axios from "axios";
let API_URL = process.env.REACT_APP_BACKEND_URL;

export const getGreenometer = () => {
  return Axios.get(API_URL + `greenometer`);
};

export const getGreenometerFormula = () => {
  return Axios.get(API_URL + `greenometer/show`);
};

export const createGreenometer = (values) => {
  return Axios.post(API_URL + "Greenometer/create", values);
};

export const updateGreenometer = (id, values) => {
  return Axios.put(API_URL + `Greenometer/update/${id}`, values);
};

export const deleteGreenometer = (id) => {
  return Axios.put(API_URL + `Greenometer/delete/${id}`);
};
