import React, { useCallback, useEffect, useState } from "react";
import { Card, Tab, Nav, Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import Table from "../../components/customTable/table";
import ContactModal from "../../components/modals/ContactModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getContact, deleteContact } from "../../services/contact";
const Contact = () => {
  const [currentTab, setCurrentTab] = useState(false);
  const [state, setState] = useState({
    activeTab: "",
    basicModal: false,
    tabData: [],
    data: [],
  });

  const tabData = [
    {
      name: "Official Distributor",
    },
    {
      name: "Office",
    },
    {
      name: "Stock point",
    },
  ];

  const tableHeader = [
    "#",
    "Title",
    "Name",
    "Rank",
    "Email",
    "Phone",
    "Address",
    "Address Details",
    "Action",
  ];
  // Get
  const getCall = (tab) => {
    getContact().then((response) => {
      const data = response.data.data;
      if (tab) {
        setCurrentTab(tab);
        setState((st) => ({
          ...st,
          activeTab: tab,
          data: data,
        }));
      } else {
        setCurrentTab(tabData[0].name);
        setState((st) => ({
          ...st,
          activeTab: tabData[0].name,
          data: data,
        }));
      }
    });
  };
  // Delete
  const deleteCall = (id) => {
    deleteContact(id)
      .then((response) => {
        if (response.data.success === true) {
          getCall(state.activeTab);
          if (response.data.data.affected === 1) {
            notify("success", "Recored Deleted");
          }
        }
      })
      .catch((error) => {
        error.response.statusText == ""
          ? notify(error.response.data.data.message)
          : notify(error.response.statusText);
      });
  };
  useEffect(() => {
    getCall();
  }, []);
  const setRecord = useCallback((record) => {
    setState((st) => ({ ...st, record, basicModal: true, tabData: "" }));
  }, []);
  const filterData = state.data.filter((obj) => obj.type === state.activeTab);
  // Notification
  const notify = (status, text) => {
    if (status == "success") {
      toast.success(`✅ ${text}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else if (status == "error") {
      toast.error(`❌ ${text} !`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
    }
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Parent Modal */}
      <ContactModal
        StateData={state}
        SetState={setState}
        GetData={getCall}
        setNotification={notify}
      />
      {state.activeTab === currentTab ? (
        <>
          <div className="form-row">
            <div className="form-group col-md-12">
              <Button
                className="mr-2 btn-rounded"
                variant="primary"
                onClick={() =>
                  setState((st) => ({
                    ...st,
                    record: "",
                    basicModal: true,
                    tabData: tabData,
                  }))
                }
              >
                Add Contact
              </Button>
            </div>
          </div>
          <Card>
            <Card.Header>
              <Card.Title>Contact </Card.Title>
            </Card.Header>
            <Card.Body>
              {/* <!-- Nav tabs --> */}
              <div className="custom-tab-1">
                <Tab.Container defaultActiveKey={state.activeTab}>
                  <Nav as="ul" className="nav-tabs">
                    {tabData.map((data, i) => (
                      <Nav.Item as="li" key={i}>
                        <Nav.Link
                          onClick={() => {
                            setCurrentTab(data.name);
                            setState((st) => ({
                              ...st,
                              activeTab: data.name,
                            }));
                          }}
                          eventKey={data.name}
                        >
                          {/* <i className={`la la-${data.icon} mr-2`} /> */}
                          {data.name}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                  <Tab.Content activeTab={state.activeTab} className="pt-4">
                    <Tab.Pane eventKey={state.activeTab}>
                      <Table
                        data={filterData}
                        headers={tableHeader}
                        onEdit={setRecord}
                        onDelete={deleteCall}
                        pageName="contact"
                      />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Card.Body>
          </Card>
        </>
      ) : (
        <div className="row">
          <Spinner animation="border" className="m-auto" />
        </div>
      )}
    </>
  );
};
export default Contact;
