import Axios from "axios";
let API_URL = process.env.REACT_APP_BACKEND_URL;

export const getPartner = () => {
  return Axios.get(API_URL + `partners/`);
};

export const createPartner = (values) => {
  return Axios.post(API_URL + "partners/create", values);
};

export const updatePartner = (id, values) => {
  return Axios.put(API_URL + `partners/update/${id}`, values);
};

export const deletePartner = (id) => {
  return Axios.put(API_URL + `partners/delete/${id}`);
};
