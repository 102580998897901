import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoginService } from "../services/login";

const Login = ({ history }) => {
  // Schema for yup
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("*Must be a valid email address")
      .max(100, "*Email must be less than 100 characters")
      .required("*Email is required"),
    password: Yup.string().required("* Password is required"),
    // .min(8, "* Password must be 8 digits long"),
  });

  const notifyError = (error) => {
    toast.error(`❌ Error ${error} !`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      onSubmit={(values) => {
        LoginService(values)
          .then((response) => {
            // clear local Storage
            localStorage.clear();
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem("token", response.data.data.auth.token);
            localStorage.setItem("id", response.data.data.user.id);
            localStorage.setItem("role", response.data.data.user.roleId);
            history.push("/");
          })
          .catch((error) => {
            error.response
              ? notifyError(error.response.data.data.message)
              : notifyError("Unauthorized");
          });
      }}
      // Hooks up our validationSchema to Formik
      validationSchema={validationSchema}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;
        return (
          <div className="authincation h-100 p-meddle">
            <div className="container h-100">
              <div className="row justify-content-center h-100 align-items-center">
                <div className="col-md-6">
                  <div className="authincation-content">
                    <div className="row no-gutters">
                      <div className="col-xl-12">
                        <div className="auth-form">
                          <h4 className="text-center mb-4">
                            Sign in your account
                          </h4>
                          <ToastContainer
                            position="top-right"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                          />
                          <form action="" onSubmit={handleSubmit}>
                            <div
                              className={`form-group ${
                                values.username
                                  ? errors.username
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="mb-1">
                                <strong>Email</strong>
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                // defaultValue="hello@example.com"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.email && errors.email}
                              </div>
                            </div>
                            <div
                              className={`form-group ${
                                values.password
                                  ? errors.password
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="mb-1">
                                <strong>Password</strong>
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                // defaultValue="Password"
                                name="password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.password && errors.password}
                              </div>
                            </div>
                            <div className="form-row d-flex justify-content-between mt-4 mb-2">
                              {/* <div className="form-group">
                                       <div className="custom-control custom-checkbox ml-1">
                                          <input
                                             type="checkbox"
                                             className="custom-control-input"
                                             id="basic_checkbox_1"
                                          />
                                          <label
                                             className="custom-control-label"
                                             htmlFor="basic_checkbox_1"
                                          >
                                             Remember my preference
                                          </label>
                                       </div>
                                    </div> */}
                              {/* <div className="form-group">
                          <Link to="/page-forgot-password">
                            Forgot Password?
                          </Link>
                        </div> */}
                            </div>
                            <div className="text-center">
                              <input
                                type="submit"
                                value="Sign In"
                                className="btn btn-primary btn-block"
                              />
                            </div>
                          </form>
                          {/* <div className="new-account mt-3">
                      <p>
                        Don't have an account?{" "}
                        <Link className="text-primary" to="/page-register">
                          Sign up
                        </Link>
                      </p>
                    </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default Login;
